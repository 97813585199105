import classNames from 'classnames/bind';

import styles from './S04.module.scss';

const cx = classNames.bind(styles);

const S04 = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('limiter')}>
        <h2 className={cx('headline')}>
          즐거운 칵테일 라이프를 위한
          <br />
          <strong>브리지톡 토닉워터 레시피</strong>
        </h2>
        <div className={cx('tip')}>TIP</div>
        <p className={cx('desc')}>취향에 따라 레시피의 비율을 조절하거나, 과일 등 가니쉬를 추가해 드시면 더욱 맛있습니다.</p>
        <ul className={cx('wrapper')}>
          <li className={cx('box')}>
            <div>
              <h3>Mojito</h3>
              <h3>모히또</h3>
            </div>
            <img src={require('../../../assets/images/products/tonic/s05/feature01.png')} alt="모히또" />
            <div className={cx('bottom')}>
              <div className={cx('text')}>
                소주 <strong>2</strong> : 토닉워터 <strong>5</strong>
                <br />+ 빻은 민트잎
              </div>
            </div>
          </li>
          <li className={cx('box')}>
            <div>
              <h3>Gin & Tonic</h3>
              <h3>진토닉</h3>
            </div>
            <img src={require('../../../assets/images/products/tonic/s05/feature02.png')} alt="모히또" />
            <div className={cx('bottom')}>
              <div className={cx('text')}>
                진 <strong>1</strong> : 토닉워터 <strong>2</strong>
              </div>
            </div>
          </li>
          <li className={cx('box')}>
            <div>
              <h3>Highball</h3>
              <h3>하이볼</h3>
            </div>
            <img src={require('../../../assets/images/products/tonic/s05/feature03.png')} alt="모히또" />
            <div className={cx('bottom')}>
              <div className={cx('text')}>
                위스키 <strong>1</strong> : 토닉워터 <strong>2</strong>
              </div>
            </div>
          </li>
          <li className={cx('box')}>
            <div>
              <h3>Soju & Tonic</h3>
              <h3>소토닉</h3>
            </div>
            <img src={require('../../../assets/images/products/tonic/s05/feature04.png')} alt="모히또" />
            <div className={cx('bottom')}>
              <div className={cx('text')}>
                소주 <strong>1</strong> : 토닉워터 <strong>2</strong>
                <br />+ 오이 슬라이스
              </div>
            </div>
          </li>
          <li className={cx('box')}>
            <div>
              <h3>Fruit cocktail</h3>
              <h3>과일 칵테일</h3>
            </div>
            <img src={require('../../../assets/images/products/tonic/s05/feature05.png')} alt="모히또" />
            <div className={cx('bottom')}>
              <div className={cx('text')}>
                보드카 <strong>1</strong> : 토닉워터 <strong>1</strong> : 과일주스 <strong>1</strong>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default S04;

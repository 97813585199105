import classNames from 'classnames/bind';

import styles from './S03.module.scss';

const cx = classNames.bind(styles);

const S03 = () => {
  return (
    <div className={cx('container')}>
      <h2 className={cx('headline')}>풀무원 퓨어는?</h2>
      <img src={require('../../../assets/images/products/pure/s03/box.png')} alt="풀무원 퓨어는?" />
      <h2 className={cx('featuresTitle')}>환경까지 생각하는 브랜드</h2>
      <ul className={cx('features')}>
        <li className={cx('feature')}>
          <img src={require('../../../assets/images/products/pure/s03/feature1.jpg')} alt="에코캡" />
          <div className={cx('title')}>에코캡</div>
          <div className={cx('desc')}>
            기존 대비 플라스틱
            <br />
            0.53g 저감
          </div>
        </li>
        <li className={cx('feature')}>
          <img src={require('../../../assets/images/products/pure/s03/feature2.jpg')} alt="클린 필 라벨" />
          <div className={cx('title')}>클린 필 라벨</div>
          <div className={cx('desc')}>
            분리가 쉽게
            <br />
            라벨이 깨끗하게 제거
          </div>
        </li>
        <li className={cx('feature')}>
          <img src={require('../../../assets/images/products/pure/s03/feature3.jpg')} alt="초경량 용기" />
          <div className={cx('title')}>초경량 용기</div>
          <div className={cx('desc')}>
            기존대비 플라스틱
            <br />
            2.36g 저감
          </div>
        </li>
      </ul>
    </div>
  );
};

export default S03;

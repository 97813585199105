import React from 'react';
import classNames from 'classnames/bind';

import styles from './S04.module.scss';
import Specs from '../../../components/product/Specs';

const cx = classNames.bind(styles);

const S04 = () => {
  return (
    <div className={cx('container')}>
      <h2 className={cx('headline')}>
        투오 미니 워터팩 전용
        <br />
        풀무원투오 미니 워터 디스펜서
      </h2>
      <div className={cx('columns')}>
        <div className={cx('column')}>
          <div className={cx('color')}>
            <div className={cx(['circle', 'white'])} />
            <div className={cx('name')}>뉴트럴 화이트</div>
          </div>
          <img src={require('../../../assets/images/products/tuo/s04/product01.jpg')} alt="뉴트럴 화이트" />
          <Specs
            data={[
              { label: '모델명', value: 'PWD-100NW' },
              { label: '컬러', value: '뉴트럴 화이트' },
              { label: '제품규격', value: '263(W)×349(D)×451(H)' },
              { label: '제품무게', value: '약 8kg' },
              { label: '정격전압', value: '220V~, 60Hz' },
              { label: '추출방식', value: '전자식 (터치버튼)' },
            ]}
          />
        </div>
        <div className={cx('column')}>
          <div className={cx('color')}>
            <div className={cx(['circle', 'black'])} />
            <div className={cx('name')}>스모키 블랙</div>
          </div>
          <img src={require('../../../assets/images/products/tuo/s04/product02.jpg')} alt="스모키 블랙" />
          <Specs
            data={[
              { label: '모델명', value: 'PWD-100SB' },
              { label: '컬러', value: '스모키 블랙' },
              { label: '제품규격', value: '263(W)×349(D)×451(H)' },
              { label: '제품무게', value: '약 8kg' },
              { label: '정격전압', value: '220V~, 60Hz' },
              { label: '추출방식', value: '전자식 (터치버튼)' },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default S04;

import classNames from 'classnames/bind';

import styles from './S05.module.scss';
import cert01 from '../../../assets/images/brand/quality/cert-01.jpg';
import cert02 from '../../../assets/images/brand/quality/cert-02.jpg';
import cert03 from '../../../assets/images/brand/quality/cert-03.jpg';
import cert04 from '../../../assets/images/brand/quality/cert-04.jpg';

const cx = classNames.bind(styles);

const S05 = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('section')}>
        <div className={cx('limiter')}>
          <h3>다양한 국제 표준 인증 획득, 품질의 차별화</h3>
          <p>각종 국제 표준 인증서 획득</p>
          <ul className={cx('list')}>
            <li>
              <h4>식품 안전</h4>
              <img src={cert01} alt="식품 안전" />
              <p>
                Food Safety <span className={cx('point')}>FSSC 22000</span>
              </p>
            </li>
            <li>
              <h4>안전</h4>
              <img src={cert02} alt="식품 안전" />
              <p>
                Safety &amp; Health <span className={cx('point')}>ISO 45001</span>
              </p>
            </li>
            <li>
              <h4>품질</h4>
              <img src={cert03} alt="식품 안전" />
              <p>
                Quality <span className={cx('point')}>ISO 9001</span>
              </p>
            </li>
            <li>
              <h4>환경</h4>
              <img src={cert04} alt="식품 안전" />
              <p>
                Environment <span className={cx('point')}>ISO 14001</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default S05;

import classNames from 'classnames/bind';

import styles from './S02.module.scss';

const cx = classNames.bind(styles);

const S02 = () => {
  return (
    <div className={cx('container')}>
      <h2 className={cx('headline')}>
        레몬농축액과 천연 레몬라임향을 넣어
        <br />더 건강하고 맛있게 즐겨요!
      </h2>
      <div className={cx('wrapper')}>
        <div className={cx('box')}>
          <img src={require('../../../assets/images/products/tonic/s03/clip01.png')} alt="" />
          <h3 className={cx('title')}>
            <strong>부드러운 탄산</strong>으로
            <br />
            칵테일 & 에이드용으로 GOOD!
          </h3>
        </div>
        <div className={cx('box')}>
          <img src={require('../../../assets/images/products/tonic/s03/clip02.png')} alt="" />
          <h3 className={cx('title')}>
            <strong>레몬농축액 첨가</strong>로<br />
            추가 믹스없이 간편하게!
          </h3>
        </div>
        <div className={cx('box')}>
          <img src={require('../../../assets/images/products/tonic/s03/clip03.png')} alt="" />
          <h3 className={cx('title')}>
            <strong>천연향료(레몬라임)</strong>로<br />
            상큼하게!
          </h3>
        </div>
      </div>
    </div>
  );
};

export default S02;

import { Helmet } from 'react-helmet';

import Jumbotron from '../../components/shared/Jumbotron';
import TabBar from '../../components/shared/TabBar';
import TabWater from './T01_water';
import TabPure from './T02_pure';
import TabRoutine from './T03_routine';
import TabDeepSea from './T04_deepsea';
import TabBreezy from './T05_breezy';
import TabTonic from './T06_tonic';
import TabTuo from './T07_tuo';
import TabBarrel from './T08_barrel';
import jumbotron from '../../assets/images/products/jumbotron.jpg';

const tabItems = [
  {
    title: '풀무원샘물',
    link: '/제품안내/풀무원샘물',
  },
  {
    title: '풀무원 퓨어',
    link: '/제품안내/풀무원-퓨어',
  },
  {
    title: '풀무원 워터루틴',
    link: '/제품안내/풀무원-워터루틴',
  },
  {
    title: '풀무원 해양심층수',
    link: '/제품안내/풀무원-해양심층수',
  },
  {
    title: '풀무원 스파클링 브리지톡',
    link: '/제품안내/풀무원-스파클링-브리지톡',
  },
  {
    title: '풀무원 브리지톡 토닉워터',
    link: '/제품안내/풀무원-브리지톡-토닉워터',
  },
  {
    title: '풀무원투오 미니 워터팩',
    link: '/제품안내/풀무원투오-미니-워터팩',
  },
  {
    title: '대용량 먹는샘물',
    link: '/제품안내/대용량-먹는샘물',
  },
];

const Products = () => {
  const tabIndex = tabItems.findIndex((navItem) => navItem.link === decodeURI(window.location.pathname));

  return (
    <div>
      <Helmet>
        <title>풀무원샘물 - 제품안내</title>
        <link
          rel="canonical"
          href="https://www.pulmuonewater.com/%EC%A0%9C%ED%92%88%EC%95%88%EB%82%B4/%ED%92%80%EB%AC%B4%EC%9B%90%EC%83%98%EB%AC%BC"
        />
      </Helmet>
      <Jumbotron title="제품안내" subtitle="Products" background={jumbotron} />
      <TabBar items={tabItems} activeIndex={tabIndex} />
      {tabIndex === 0 && <TabWater />}
      {tabIndex === 1 && <TabPure />}
      {tabIndex === 2 && <TabRoutine />}
      {tabIndex === 3 && <TabDeepSea />}
      {tabIndex === 4 && <TabBreezy />}
      {tabIndex === 5 && <TabTonic />}
      {tabIndex === 6 && <TabTuo />}
      {tabIndex === 7 && <TabBarrel />}
    </div>
  );
};

export default Products;

import { useState } from 'react';
import classNames from 'classnames/bind';

import styles from './index.module.scss';
import { finances } from '../../../assets/data/finance';
import PageTitle from '../../../components/shared/PageTitle';
import ReportCommon from './ReportCommon';
import Report2021 from './Report2021';
import Report2022 from './Report2022';
import Report2023 from './Report2023';

const cx = classNames.bind(styles);

const Finance = () => {
  const [data, setData] = useState(finances.find((item) => item.year === 2023));

  const onChange = (e) => {
    const year = Number(e.target.value);
    const nextData = finances.find((item) => item.year === year);
    setData(nextData);
  };

  return (
    <div className={cx('container')}>
      <PageTitle title="전자공시" />
      <div className={cx('selectInner')}>
        <div className={cx('select')}>
          <select onChange={onChange}>
            <option value={2023}>2023 전자공시</option>
            <option value={2022}>2022 전자공시</option>
            <option value={2021}>2021 전자공시</option>
            <option value={2020}>2020 전자공시</option>
            <option value={2019}>2019 전자공시</option>
            <option value={2018}>2018 전자공시</option>
            <option value={2017}>2017 전자공시</option>
            <option value={2016}>2016 전자공시</option>
            <option value={2015}>2015 전자공시</option>
            <option value={2014}>2014 전자공시</option>
          </select>
        </div>
      </div>
      <div className={cx('section')}>
        {data?.year === 2023 && <Report2023 />}
        {data?.year === 2022 && <Report2022 />}
        {data?.year === 2021 && <Report2021 />}
        {data?.year < 2021 && <ReportCommon data={data} />}
      </div>
    </div>
  );
};

export default Finance;

import classNames from 'classnames/bind';

import styles from './S04.module.scss';
import product from '../../../assets/images/products/deepsea/s04/product.png';
import Download from '../../../components/product/Download';
import Nutrient from '../../../components/product/Nutrient';
import Products from '../../../components/product/Products';

const cx = classNames.bind(styles);

const S04 = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('limiter')}>
        <Products
          products={[{ sub: '외출, 아웃도어 용으로', title: '해양심층수 500mL', image: product, text: '500mL × 20개입' }]}
          color="#005f9a"
        />
        <Nutrient
          data={{
            head: ['마그네슘(Mg)', '칼슘(Ca)', '칼륨(K)', '나트륨(Na)'],
            body: [['15 ~ 26', '5 ~ 9', '4 ~ 9', '3 ~ 11']],
          }}
          color="#64bae8"
        />
        <Download
          title="해양심층수는 안심하고 드실 수 있습니다!"
          names={['수질 성적 검사서']}
          files={['/static/report/Water_Report-Deepsea.pdf']}
          color="#005f9a"
        />
      </div>
    </div>
  );
};

export default S04;

import classNames from 'classnames/bind';

import styles from './S05.module.scss';

const cx = classNames.bind(styles);

const S05 = () => {
  return (
    <div className={cx('container')}>
      <h2 className={cx('headline')}>
        워터 라이프를 완성하는
        <br />
        투오 미니 워터 디스펜서
      </h2>
      <div className={cx(['columns', 'places'])}>
        <div className={cx('column')}>
          <img src={require('../../../assets/images/products/tuo/s05/place01.jpg')} alt="주방" />
        </div>
        <div className={cx('column')}>
          <img src={require('../../../assets/images/products/tuo/s05/place02.jpg')} alt="침실" />
        </div>
        <div className={cx('column')}>
          <img src={require('../../../assets/images/products/tuo/s05/place03.jpg')} alt="사무실" />
        </div>
      </div>
      <div className={cx('wrapper')}>
        <div className={cx('left')}>
          <div className={cx('title1')}>설치 제약 없이 자유롭게</div>
          <div className={cx('title2')}>공간의 경계를 허물다</div>
        </div>
        <div className={cx('right')}>
          워터팩 전용 제품인 투오 미니 워터 디스펜서는 <strong>방문설치나 유로 연결, 타공이 필요 없습니다.</strong> 배송 후{' '}
          <strong>주방, 침실, 사무실</strong> 등 원하는 공간에 전원 코드만 꽂는다면 셀프 설치가 가능합니다.
        </div>
      </div>
      <div className={cx('columns')}>
        <div className={cx('column')}>
          <img src={require('../../../assets/images/products/tuo/s05/feature01.jpg')} alt="주방" />
          <div className={cx('title')}>진공 니들 흡입 기술</div>
          <div className={cx('desc')}>
            진공 니들 흡입 기술로 산소와 외부 이물질의 접촉을 차단해 워터팩의 미네랄 워터를 신선하게 음용하게 해줍니다.
          </div>
        </div>
        <div className={cx('column')}>
          <img src={require('../../../assets/images/products/tuo/s05/feature02.jpg')} alt="침실" />
          <div className={cx('title')}>반도체 냉각 시스템</div>
          <div className={cx('desc')}>반도체 냉각방식을 적용해 최대 소음 약25dB로 소음을 줄이고 냉각 온도를 정밀하게 제어합니다.</div>
        </div>
        <div className={cx('column')}>
          <img src={require('../../../assets/images/products/tuo/s05/feature03.jpg')} alt="사무실" />
          <div className={cx('title')}>스테인리스 유로 & 탱크</div>
          <div className={cx('desc')}>스테인리스 스틸 소재를 적용해 제품 내구성과 위생성을 향상시켜 줍니다.</div>
        </div>
        <div className={cx('column')}>
          <img src={require('../../../assets/images/products/tuo/s05/feature04.jpg')} alt="주방" />
          <div className={cx('title')}>UV LED 살균 기술</div>
          <div className={cx('desc')}>냉수 탱크 내 UV LED를 장착해 유해세균을 99.9% 제거합니다.</div>
        </div>
        <div className={cx('column')}>
          <img src={require('../../../assets/images/products/tuo/s05/feature05.jpg')} alt="침실" />
          <div className={cx('title')}>냉온수 출수</div>
          <div className={cx('desc')}>2단계 온수 & 냉수 모드를 적용해 다양한 온도의 물을 편리하게 음용할 수 있습니다.</div>
        </div>
        <div className={cx('column')}>
          <img src={require('../../../assets/images/products/tuo/s05/feature06.jpg')} alt="사무실" />
          <div className={cx('title')}>터치 디스플레이</div>
          <div className={cx('desc')}>처음 투오 디스펜서를 접하는 누구든 편리하게 사용할 수 있습니다</div>
        </div>
      </div>
      <div className={cx('box')}>
        * 진공 니들 흡입 : 워터팩 사용시 자동 및 연속 출수량이 점차 줄어드는 구조로 워터팩 사용후 일부 잔수가 남을 수 있습니다.
        <br />
        * 99.9% 살균 : ’99.9% 살균;은 대장균, 녹농균의 제거 효율을 의미합니다. 대장균, 녹농균의 450ml 조제수에 20분간 UVC 모듈을 20분간
        작동, 검체 내 120ml를 출수해 분석한 제거 효율로, 외부 기관인 한국환경수도연구원(2024년 4월)에 의뢰한 시험 결과입니다. 실험실 측정
        기준으로 시험조건과 환경에 따라 달라질 수 있습니다.
        <br />* 냉온수 출수 : 냉온수 온도는 제품 후면부 스위치로 조절되며, 냉각/히팅 시간이 소요될 수 있습니다.
      </div>
    </div>
  );
};

export default S05;

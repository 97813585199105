import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from './Outro.module.scss';

const cx = classNames.bind(styles);

const Outro = ({ title, order = true, shopUrl, webUrl, color = 'green' }) => {
  return (
    <div className={cx(['container', color])}>
      <div className={cx('limiter')}>
        <h2 className={cx('title')}>
          {title.split('\n').map((line, index) => (
            <span key={index}>{line}</span>
          ))}
        </h2>
        <ul className={cx(['buttons'])}>
          {order && (
            <li className={cx('button')}>
              <Link to="/고객센터/문의하기" className={cx(color)}>
                <div className={cx(['icon', 'contact', color])} />
                <div className={cx(['label', color])}>주문상담 신청하기</div>
              </Link>
            </li>
          )}
          {shopUrl && (
            <li className={cx('button')}>
              <a href={shopUrl} target="_blank" rel="noreferrer" className={cx(color)}>
                <div className={cx(['icon', 'order', color])} />
                <div className={cx(['label', color])}>바로 구매하기</div>
              </a>
            </li>
          )}
          {webUrl && (
            <li className={cx('button')}>
              <a href={webUrl} target="_blank" rel="noreferrer" className={cx(color)}>
                <div className={cx(['icon', 'web', color])} />
                <div className={cx(['label', color])}>투오 공식 웹사이트 바로가기</div>
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Outro;

import classNames from 'classnames/bind';

import styles from './S03.module.scss';

const cx = classNames.bind(styles);

const S03 = () => {
  return (
    <div className={cx('container')}>
      <h2 className={cx('headline')}>브리지톡 CHECK POINT</h2>
      <p className={cx('desc')}>천연 향료만을 사용해 산뜻하고 깔끔한 맛과 강력한 탄산이 만나 어디에나 잘 어울립니다!</p>
      <div className={cx('box')}>
        <div className={cx('feature')}>
          <div className={cx('imageWrapper')}>
            <img src={require('../../../assets/images/products/breezy/s03/feature1.png')} alt="미네랄이 풍부한 먹는샘물로 만든 탄산수" />
          </div>
          <div className={cx('wrapper')}>
            <h3 className={cx('title')}>미네랄이 풍부한 먹는샘물로 만든 탄산수</h3>
            <div className={cx('label')}>풍부한 미네랄</div>
            <p className={cx('desc')}>천연 암반수가 담긴 맑고 깨끗한 먹는샘물로 만든 탄산수 입니다.</p>
            <div className={cx('label')}>깔끔한 맛</div>
            <p className={cx('desc')}>정제수가 아닌 먹는샘물에 탄산을 더해 더 깔끔한 맛을 느낄 수 있습니다.</p>
          </div>
        </div>
        <div className={cx('feature')}>
          <div className={cx('imageWrapper')}>
            <img src={require('../../../assets/images/products/breezy/s03/feature2.png')} alt="비교할 수 없는 짜릿함! 강한 탄산" />
          </div>
          <div className={cx('wrapper')}>
            <h3 className={cx('title')}>비교할 수 없는 짜릿함! 강한 탄산</h3>
            <div className={cx('label')}>톡쏘는 짜릿함</div>
            <p className={cx('desc')}>강한 탄산감으로 갈증을 즉각적으로 해소시켜 줍니다.</p>
          </div>
        </div>
        <div className={cx('feature')}>
          <div className={cx('imageWrapper')}>
            <img src={require('../../../assets/images/products/breezy/s03/feature3.png')} alt="천연 향료로 부드러운 맛" />
          </div>
          <div className={cx('wrapper')}>
            <h3 className={cx('title')}>천연 향료로 부드러운 맛</h3>
            <div className={cx('label')}>無인공감미료</div>
            <p className={cx('desc')}>풀무원샘물만의 특별한 레시피와 천연 향료만을 사용해 부드럽고 깔끔한 맛을 느낄 수 있습니다.</p>
          </div>
        </div>
        <div className={cx('feature')}>
          <div className={cx('imageWrapper')}>
            <img src={require('../../../assets/images/products/breezy/s03/feature4.png')} alt="칼로리 걱정 없는 ZERO 칼로리" />
          </div>
          <div className={cx('wrapper')}>
            <h3 className={cx('title')}>칼로리 걱정 없는 ZERO 칼로리</h3>
            <div className={cx('label')}>0 Kcal</div>
            <p className={cx('desc')}>설탕, 당류 등을 첨가하지 않아 칼로리 걱정 없이 즐길 수 있습니다.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default S03;

import { Helmet } from 'react-helmet';

import Jumbotron from '../../components/shared/Jumbotron';
import TabBar from '../../components/shared/TabBar';
import TabShare from './T01_share';
import TabEco from './T02_eco';
import jumbotron from '../../assets/images/csr/jumbotron.jpg';

const tabItems = [
  {
    title: '나눔 캠페인',
    link: '/CSR/나눔-캠페인',
  },
  {
    title: '친환경 캠페인',
    link: '/CSR/친환경-캠페인',
  },
];

const CSR = () => {
  const tabIndex = tabItems.findIndex((navItem) => navItem.link === decodeURI(window.location.pathname));

  return (
    <div>
      <Helmet>
        <title>풀무원샘물 - CSR</title>
        <link rel="canonical" href="https://www.pulmuonewater.com/CSR/%EC%88%98%EC%9E%90%EC%9B%90-%EB%B3%B4%ED%98%B8" />
      </Helmet>
      <Jumbotron title="CSR" subtitle="Corporate Social Responsibility" background={jumbotron} />
      <TabBar items={tabItems} activeIndex={tabIndex} />
      {tabIndex === 0 && <TabShare />}
      {tabIndex === 1 && <TabEco />}
    </div>
  );
};

export default CSR;

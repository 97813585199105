import classNames from 'classnames/bind';

import styles from './S02.module.scss';
import product01 from '../../../assets/images/products/routine/s02/product01.jpg';
import product02 from '../../../assets/images/products/routine/s02/product02.jpg';
import Download from '../../../components/product/Download';
import Nutrient from '../../../components/product/Nutrient';
import Products from '../../../components/product/Products';

const cx = classNames.bind(styles);

const S02 = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('limiter')}>
        <Products
          products={[
            { sub: '가정 식수로 적합한', title: '워터루틴 2L', image: product01, text: '2L × 6개입' },
            { sub: '외출, 아웃도어 용으로', title: '워터루틴 500mL', image: product02, text: '500mL × 20개입' },
          ]}
          color="#005f9a"
        />
        <Nutrient
          data={{
            head: ['칼슘(Ca)', '나트륨(Na)', '마그네슘(Mg)', '칼륨(K)', '불소(F)'],
            body: [['9.7 ~ 14.7', '5.2 ~ 8.0', '1.3 ~ 2.1', '0.3 ~ 0.6', '0.0 ~ 0.2']],
          }}
          color="#64bae8"
        />
        <Download
          title="워터루틴은 안심하고 드실 수 있습니다!"
          names={['수질 성적 검사서']}
          files={['/static/report/Water_Report-Pulmuone_Routine.pdf']}
          color="#005f9a"
        />
      </div>
    </div>
  );
};

export default S02;

export const finances = [
  {
    year: 2023,
  },
  {
    year: 2022,
  },
  {
    year: 2021,
  },
  {
    year: 2020,
    finance: 18,
    financialName: '한영회계법인 대표이사 박용근',
    name: '조현근',
    data: [
      {
        subject: '자산',
        point: true,
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 유동자산',
        point: true,
        cardinalAfter: ['', '12,437,382,935'],
        cardinalBefore: ['', '11,147,101,577'],
      },
      {
        subject: '(1) 당좌자산',
        point: true,
        cardinalAfter: ['', '7,555,444,092'],
        cardinalBefore: ['', '7,627,965,745'],
      },
      {
        subject: '현금 및 현금성자산',
        cardinalAfter: ['159,756,332', ''],
        cardinalBefore: ['199,790,872', ''],
      },
      {
        subject: '매출채권',
        cardinalAfter: ['6,293,761,302', ''],
        cardinalBefore: ['6,184,713,239', ''],
      },
      {
        subject: '대손충당금',
        cardinalAfter: ['(14,071,000)', ''],
        cardinalBefore: ['(66,888,000)', ''],
      },
      {
        subject: '미수금',
        cardinalAfter: ['189,332,417', ''],
        cardinalBefore: ['608,574,697', ''],
      },
      {
        subject: '대손충당금',
        cardinalAfter: ['(99,742,494)', ''],
        cardinalBefore: ['(99,742,494)', ''],
      },
      {
        subject: '선급금',
        cardinalAfter: ['206,000,000', ''],
        cardinalBefore: ['206,000,000', ''],
      },
      {
        subject: '선급비용',
        cardinalAfter: ['171,190,571', ''],
        cardinalBefore: ['163,026,944', ''],
      },
      {
        subject: '미수수익',
        cardinalAfter: ['9,968,935', ''],
        cardinalBefore: ['-', ''],
      },
      {
        subject: '유동성장기대여금',
        cardinalAfter: ['204,000,000', ''],
        cardinalBefore: ['-', ''],
      },
      {
        subject: '유동이연법인세자산',
        cardinalAfter: ['435,248,029', ''],
        cardinalBefore: ['432,490,487', ''],
      },
      {
        subject: '(2) 재고자산',
        point: true,
        cardinalAfter: ['', '4,881,938,843'],
        cardinalBefore: ['', '3,519,135,832'],
      },
      {
        subject: '상품',
        cardinalAfter: ['25,343,695', ''],
        cardinalBefore: ['21,562,407', ''],
      },
      {
        subject: '제품',
        cardinalAfter: ['1,145,668,118', ''],
        cardinalBefore: ['837,901,331', ''],
      },
      {
        subject: '저장품',
        cardinalAfter: ['3,710,927,030', ''],
        cardinalBefore: ['2,659,672,094', ''],
      },
      {
        subject: '자산',
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'II. 비유동자산',
        point: true,
        cardinalAfter: ['', '39,660,185,925'],
        cardinalBefore: ['', '41,505,737,311'],
      },
      {
        subject: '(1) 투자자산',
        point: true,
        cardinalAfter: ['', '60,000,000'],
        cardinalBefore: ['', ''],
      },
      {
        subject: '장기대여금',
        cardinalAfter: ['60,000,000', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: '(2) 유형자산(주5,6)',
        point: true,
        cardinalAfter: ['', '30,152,387,192'],
        cardinalBefore: ['', '31,824,078,423'],
      },
      {
        subject: '토지',
        cardinalAfter: ['3,011,932,605', ''],
        cardinalBefore: ['3,011,932,605', ''],
      },
      {
        subject: '건물',
        cardinalAfter: ['13,497,973,677', ''],
        cardinalBefore: ['13,431,521,877', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['-2,520,571,603', ''],
        cardinalBefore: ['-2,183,184,856', ''],
      },
      {
        subject: '부대설비',
        cardinalAfter: ['8,849,286', ''],
        cardinalBefore: ['8,849,286', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['-4,314,977', ''],
        cardinalBefore: ['-4,028,002', ''],
      },
      {
        subject: '구축물',
        cardinalAfter: ['3,887,937,962', ''],
        cardinalBefore: ['3,882,016,962', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['-659,902,900', ''],
        cardinalBefore: ['-548,366,680', ''],
      },
      {
        subject: '기계장치',
        cardinalAfter: ['29,790,133,025', ''],
        cardinalBefore: ['29,435,648,653', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['-22,712,237,065', ''],
        cardinalBefore: ['-20,670,887,238', ''],
      },
      {
        subject: '차량운반구',
        cardinalAfter: ['48,383,568', ''],
        cardinalBefore: ['48,383,568', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['-48,380,568', ''],
        cardinalBefore: ['-48,380,568', ''],
      },
      {
        subject: '공기구비품',
        cardinalAfter: ['14,405,616,074', ''],
        cardinalBefore: ['13,898,610,556', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['-8,930,459,671', ''],
        cardinalBefore: ['-8,513,814,740', ''],
      },
      {
        subject: '손상차손누계액',
        cardinalAfter: ['', ''],
        cardinalBefore: ['-35,623,000', ''],
      },
      {
        subject: '건설중인자산',
        cardinalAfter: ['377,427,779', ''],
        cardinalBefore: ['111,400,000', ''],
      },
      {
        subject: '(3) 무형자산(주7)',
        point: true,
        cardinalAfter: ['', '7,030,449,946'],
        cardinalBefore: ['', '7,629,149,259'],
      },
      {
        subject: '소프트웨어',
        cardinalAfter: ['200,523,434', ''],
        cardinalBefore: ['258,851,834', ''],
      },
      {
        subject: '기타의무형자산',
        cardinalAfter: ['6,793,194,512', ''],
        cardinalBefore: ['7,340,297,425', ''],
      },
      {
        subject: '건설중인자산',
        cardinalAfter: ['36,732,000', ''],
        cardinalBefore: ['30,000,000', ''],
      },
      {
        subject: '(4)기타비유동자산',
        point: true,
        cardinalAfter: ['', '2,417,348,787'],
        cardinalBefore: ['', '2,052,509,629'],
      },
      {
        subject: '보증금',
        cardinalAfter: ['1,392,612,011', ''],
        cardinalBefore: ['1,195,356,011', ''],
      },
      {
        subject: '비유동이연법인세자산(주15)',
        cardinalAfter: ['1,024,736,776', ''],
        cardinalBefore: ['857,153,618', ''],
      },
      {
        subject: '자산총계',
        cardinalAfter: ['', '52,097,568,860'],
        cardinalBefore: ['', '52,652,838,888'],
      },
      {
        subject: '부채',
        point: true,
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 유동부채',
        point: true,
        cardinalAfter: ['', '20,871,585,814'],
        cardinalBefore: ['', '30,579,563,571'],
      },
      {
        subject: '단기차입금(주8,9)',
        cardinalAfter: ['5,021,521,813', ''],
        cardinalBefore: ['15,037,681,110', ''],
      },
      {
        subject: '매입채무(주8)',
        cardinalAfter: ['4,124,345,340', ''],
        cardinalBefore: ['4,284,003,251', ''],
      },
      {
        subject: '미지급금(주4,8,11)',
        cardinalAfter: ['4,728,491,025', ''],
        cardinalBefore: ['4,221,852,287', ''],
      },
      {
        subject: '미지급비용(주8)',
        cardinalAfter: ['1,927,334,449', ''],
        cardinalBefore: ['1,938,857,404', ''],
      },
      {
        subject: '선수금',
        cardinalAfter: ['935,201,318', ''],
        cardinalBefore: ['742,217,940', ''],
      },
      {
        subject: '예수금',
        cardinalAfter: ['140,572,093', ''],
        cardinalBefore: ['108,554,619', ''],
      },
      {
        subject: '부가세예수금',
        cardinalAfter: ['609,669,070', ''],
        cardinalBefore: ['720,607,231', ''],
      },
      {
        subject: '수입보증금',
        cardinalAfter: ['1,634,348,967', ''],
        cardinalBefore: ['1,693,222,760', ''],
      },
      {
        subject: '가맹보증금',
        cardinalAfter: ['212,540,000', ''],
        cardinalBefore: ['227,540,000', ''],
      },
      {
        subject: '당기법인세부채(주15)',
        cardinalAfter: ['1,537,561,739', ''],
        cardinalBefore: ['1,605,026,969', ''],
      },
      {
        subject: 'II. 비유동부채',
        point: true,
        cardinalAfter: ['', '4,324,976,752'],
        cardinalBefore: ['', '3,498,645,003'],
      },
      {
        subject: '퇴직급여충당부채(주10)',
        cardinalAfter: ['4,368,005,678', ''],
        cardinalBefore: ['3,541,147,564', ''],
      },
      {
        subject: '퇴직보험예치금(주10)',
        cardinalAfter: ['41,251,526', ''],
        cardinalBefore: ['-40,725,161', ''],
      },
      {
        subject: '국민연금전환금',
        cardinalAfter: ['1,777,400', ''],
        cardinalBefore: ['-1,777,400', ''],
      },
      {
        subject: '부채총계',
        cardinalAfter: ['', '25,196,562,566'],
        cardinalBefore: ['', '34,078,208,574'],
      },
      {
        subject: '자본',
        point: true,
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 자본금(주1,13)',
        point: true,
        cardinalAfter: ['', '6,778,760,000'],
        cardinalBefore: ['', '6,778,760,000'],
      },
      {
        subject: '보통주자본금',
        cardinalAfter: ['6,778,760,000', ''],
        cardinalBefore: ['6,778,760,000', ''],
      },
      {
        subject: 'II. 자본잉여금(주13)',
        point: true,
        cardinalAfter: ['', '6,751,882,994'],
        cardinalBefore: ['', '6,751,882,994'],
      },
      {
        subject: '주식발행초과금',
        cardinalAfter: ['6,751,882,994', ''],
        cardinalBefore: ['6,751,882,994', ''],
      },
      {
        subject: 'III. 이익잉여금(주14)',
        point: true,
        cardinalAfter: ['', '13,370,363,300'],
        cardinalBefore: ['', '5,043,987,320'],
      },
      {
        subject: '임의적립금',
        cardinalAfter: ['88,000,000', ''],
        cardinalBefore: ['88,000,000', ''],
      },
      {
        subject: '미처분이익잉여금',
        cardinalAfter: ['13,282,363,300', ''],
        cardinalBefore: ['4,955,987,320', ''],
      },
      {
        subject: '자본총계',
        cardinalAfter: ['', '26,901,006,294'],
        cardinalBefore: ['', '18,574,630,314'],
      },
      {
        subject: '부채 및 자본총계',
        cardinalAfter: ['', '52,097,568,860'],
        cardinalBefore: ['', '52,652,838,888'],
      },
    ],
  },
  {
    year: 2019,
    finance: 17,
    financialName: '삼정회계법인 대표이사 김교태',
    name: '조현근',
    data: [
      {
        subject: '자산',
        point: true,
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 유동자산',
        point: true,
        cardinalAfter: ['', '11,147,101,577'],
        cardinalBefore: ['', '11,696,209,618'],
      },
      {
        subject: '(1) 당좌자산',
        cardinalAfter: ['', '7,627,965,745'],
        cardinalBefore: ['', '8,333,417,107'],
      },
      {
        subject: '현금 및 현금성자산',
        cardinalAfter: ['199,790,872', ''],
        cardinalBefore: ['150,200,738', ''],
      },
      {
        subject: '매출채권',
        cardinalAfter: ['6,184,713,239', ''],
        cardinalBefore: ['6,023,923,655', ''],
      },
      {
        subject: '대손충당금',
        cardinalAfter: ['(66,888,000)', ''],
        cardinalBefore: ['(95,138,000)', ''],
      },
      {
        subject: '미수금',
        cardinalAfter: ['608,574,697', ''],
        cardinalBefore: ['1,600,013,421', ''],
      },
      {
        subject: '대손충당금',
        cardinalAfter: ['(99,742,494)', ''],
        cardinalBefore: ['(99,742,494)', ''],
      },
      {
        subject: '단기대여금',
        cardinalAfter: ['—', ''],
        cardinalBefore: ['5,000,000', ''],
      },
      {
        subject: '선급금',
        cardinalAfter: ['206,000,000', ''],
        cardinalBefore: ['335,484,500', ''],
      },
      {
        subject: '선급비용',
        cardinalAfter: ['163,026,944', ''],
        cardinalBefore: ['77,561,346', ''],
      },
      {
        subject: '유동이연법인세자산',
        cardinalAfter: ['432,490,487', ''],
        cardinalBefore: ['336,113,941', ''],
      },
      {
        subject: '(2) 재고자산',
        cardinalAfter: ['', '3,519,135,832'],
        cardinalBefore: ['', '3,362,792,511'],
      },
      {
        subject: '상품',
        cardinalAfter: ['21,562,407', ''],
        cardinalBefore: ['27,660,480', ''],
      },
      {
        subject: '제품',
        cardinalAfter: ['837,901,331', ''],
        cardinalBefore: ['840,921,606', ''],
      },
      {
        subject: '저장품',
        cardinalAfter: ['2,659,672,094', ''],
        cardinalBefore: ['2,494,210,425', ''],
      },
      {
        subject: 'II. 비유동자산',
        point: true,
        cardinalAfter: ['', '41,505,737,311'],
        cardinalBefore: ['', '43,856,051,506'],
      },
      {
        subject: '(1) 유형자산',
        point: true,
        cardinalAfter: ['', '31,824,078,423'],
        cardinalBefore: ['', '33,835,469,179'],
      },
      {
        subject: '토지',
        cardinalAfter: ['3,011,932,605', ''],
        cardinalBefore: ['3,011,932,605', ''],
      },
      {
        subject: '건물',
        cardinalAfter: ['13,431,521,877', ''],
        cardinalBefore: ['13,317,401,877', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['(2,183,184,856)', ''],
        cardinalBefore: ['(1,849,097,695)', ''],
      },
      {
        subject: '부대설비',
        cardinalAfter: ['8,849,286', ''],
        cardinalBefore: ['8,849,286', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['(4,028,002)', ''],
        cardinalBefore: ['(3,741,027)', ''],
      },
      {
        subject: '구축물',
        cardinalAfter: ['3,882,016,962', ''],
        cardinalBefore: ['3,461,062,417', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['(548,366,680)', ''],
        cardinalBefore: ['(454,267,688)', ''],
      },
      {
        subject: '기계장치',
        cardinalAfter: ['29,435,648,653', ''],
        cardinalBefore: ['29,157,283,367', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['(20,670,887,238)', ''],
        cardinalBefore: ['(17,823,551,453)', ''],
      },
      {
        subject: '차량운반구',
        cardinalAfter: ['48,383,588', ''],
        cardinalBefore: ['48,383,588', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['(48,380,568)', ''],
        cardinalBefore: ['(48,380,568)', ''],
      },
      {
        subject: '공기구비품',
        cardinalAfter: ['13,898,610,556', ''],
        cardinalBefore: ['14,852,701,621', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['(8,513,814,740)', ''],
        cardinalBefore: ['(9,909,984,131)', ''],
      },
      {
        subject: '손상차손누계액',
        cardinalAfter: ['(35,623,000)', ''],
        cardinalBefore: ['(35,623,000)', ''],
      },
      {
        subject: '건설중인자산',
        cardinalAfter: ['111,400,000', ''],
        cardinalBefore: ['102,500,000', ''],
      },
      {
        subject: '(2) 무형자산',
        point: true,
        cardinalAfter: ['', '7,629,149,259'],
        cardinalBefore: ['', '8,023,739,505'],
      },
      {
        subject: '소프트웨어',
        cardinalAfter: ['258,851,834', ''],
        cardinalBefore: ['136,339,167', ''],
      },
      {
        subject: '기타의무형자산',
        cardinalAfter: ['7,340,297,425', ''],
        cardinalBefore: ['7,887,400,338', ''],
      },
      {
        subject: '건설중인자산',
        cardinalAfter: ['30,000,000', ''],
        cardinalBefore: ['-', ''],
      },
      {
        subject: '(3) 기타비유동자산',
        cardinalAfter: ['', '2,052,509,629'],
        cardinalBefore: ['', '1,996,842,822'],
      },
      {
        subject: '보증금',
        cardinalAfter: ['1,195,356,011', ''],
        cardinalBefore: ['1,186,559,176', ''],
      },
      {
        subject: '비유동이연법인세자산',
        cardinalAfter: ['857,153,618', ''],
        cardinalBefore: ['810,283,646', ''],
      },
      {
        subject: '자산총계',
        cardinalAfter: ['', '52,652,838,888'],
        cardinalBefore: ['', '55,552,261,124'],
      },
      {
        subject: '부채',
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 유동부채',
        point: true,
        cardinalAfter: ['', '30,579,563,571'],
        cardinalBefore: ['', '40,972,997,864'],
      },
      {
        subject: '단기차입금',
        cardinalAfter: ['15,037,681,110', ''],
        cardinalBefore: ['27,881,528,741', ''],
      },
      {
        subject: '매입채무',
        cardinalAfter: ['4,284,003,251', ''],
        cardinalBefore: ['3,663,081,014', ''],
      },
      {
        subject: '미지급금',
        cardinalAfter: ['4,221,852,287', ''],
        cardinalBefore: ['3,379,141,313', ''],
      },
      {
        subject: '미지급비용',
        cardinalAfter: ['1,938,857,404', ''],
        cardinalBefore: ['1,425,347,964', ''],
      },
      {
        subject: '선수금',
        cardinalAfter: ['742,217,940', ''],
        cardinalBefore: ['594,139,945', ''],
      },
      {
        subject: '예수금',
        cardinalAfter: ['108,554,619', ''],
        cardinalBefore: ['105,618,501', ''],
      },
      {
        subject: '부가세예수금',
        cardinalAfter: ['720,607,231', ''],
        cardinalBefore: ['634,562,960', ''],
      },
      {
        subject: '수입보증금',
        cardinalAfter: ['1,693,222,760', ''],
        cardinalBefore: ['1,712,579,960', ''],
      },
      {
        subject: '가맹보증금',
        cardinalAfter: ['227,540,000', ''],
        cardinalBefore: ['178,000,000', ''],
      },
      {
        subject: '당기법인세부채',
        cardinalAfter: ['1,605,026,969', ''],
        cardinalBefore: ['1,398,997,466', ''],
      },
      {
        subject: 'II. 비유동부채',
        point: true,
        cardinalAfter: ['', '3,498,645,003'],
        cardinalBefore: ['', '3,394,636,842'],
      },
      {
        subject: '퇴직급여충당부채',
        cardinalAfter: ['3,541,147,564', ''],
        cardinalBefore: ['3,436,619,785', ''],
      },
      {
        subject: '퇴직보험예치금',
        cardinalAfter: ['(40,725,161)', ''],
        cardinalBefore: ['(40,205,543)', ''],
      },
      {
        subject: '국민연금전환금',
        cardinalAfter: ['(1,777,400)', ''],
        cardinalBefore: ['(1,777,400)', ''],
      },
      {
        subject: '부채총계',
        cardinalAfter: ['', '34,078,208,574'],
        cardinalBefore: ['', '44,367,634,706'],
      },
      {
        subject: '자본',
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 자본금',
        point: true,
        cardinalAfter: ['', '6,778,760,000'],
        cardinalBefore: ['', '6,778,760,000'],
      },
      {
        subject: '보통주자본금',
        cardinalAfter: ['6,778,760,000', ''],
        cardinalBefore: ['6,778,760,000', ''],
      },
      {
        subject: 'II. 자본잉여금',
        point: true,
        cardinalAfter: ['', '6,751,882,994'],
        cardinalBefore: ['', '6,751,882,994'],
      },
      {
        subject: '주식발행초과금',
        cardinalAfter: ['6,751,882,994', ''],
        cardinalBefore: ['6,751,882,994', ''],
      },
      {
        subject: 'III. 이익잉여금',
        point: true,
        cardinalAfter: ['', '5,043,987,320'],
        cardinalBefore: ['', '(2,346,016,576)'],
      },
      {
        subject: '임의적립금',
        cardinalAfter: ['88,000,000', ''],
        cardinalBefore: ['88,000,000', ''],
      },
      {
        subject: '미처분이익잉여금',
        cardinalAfter: ['4,955,987,320', ''],
        cardinalBefore: ['(2,434,016,576)', ''],
      },
      {
        subject: '자본총계',
        cardinalAfter: ['', '18,574,630,314'],
        cardinalBefore: ['', '11,184,626,418'],
      },
      {
        subject: '부채 및 자본총계',
        cardinalAfter: ['', '52,652,838,888'],
        cardinalBefore: ['', '55,552,261,124'],
      },
    ],
  },
  {
    year: 2018,
    finance: 16,
    financialName: '삼정회계법인 대표이사 김교태',
    name: '조현근',
    data: [
      {
        subject: '자산',
        point: true,
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 유동자산',
        point: true,
        cardinalAfter: ['', '11,696,209,618'],
        cardinalBefore: ['', '15,575,971,672'],
      },
      {
        subject: '(1) 당좌자산',
        cardinalAfter: ['', '8,333,417,107'],
        cardinalBefore: ['', '12,791,660,258'],
      },
      {
        subject: '현금 및 현금성자산',
        cardinalAfter: ['150,200,738', ''],
        cardinalBefore: ['3,330,731,924', ''],
      },
      {
        subject: '매출채권',
        cardinalAfter: ['6,023,923,655', ''],
        cardinalBefore: ['6,378,491,231', ''],
      },
      {
        subject: '대손충당금',
        cardinalAfter: ['(95,138,000)', ''],
        cardinalBefore: ['(110,138,000)', ''],
      },
      {
        subject: '미수금',
        cardinalAfter: ['1,600,013,421', ''],
        cardinalBefore: ['2,527,805,091', ''],
      },
      {
        subject: '대손충당금',
        cardinalAfter: ['(99,742,494)', ''],
        cardinalBefore: ['(99,742,494)', ''],
      },
      {
        subject: '단기대여금',
        cardinalAfter: ['5,000,000', ''],
        cardinalBefore: ['-', ''],
      },
      {
        subject: '선급금',
        cardinalAfter: ['335,484,500', ''],
        cardinalBefore: ['334,268,000', ''],
      },
      {
        subject: '선급비용',
        cardinalAfter: ['77,561,346', ''],
        cardinalBefore: ['78,552,502', ''],
      },
      {
        subject: '유동이연법인세자산',
        cardinalAfter: ['336,113,941', ''],
        cardinalBefore: ['351,692,004', ''],
      },
      {
        subject: '(2) 재고자산',
        cardinalAfter: ['', '3,362,792,511'],
        cardinalBefore: ['', '2,784,311,414'],
      },
      {
        subject: '상품',
        cardinalAfter: ['27,660,480', ''],
        cardinalBefore: ['21,852,080', ''],
      },
      {
        subject: '제품',
        cardinalAfter: ['840,921,606', ''],
        cardinalBefore: ['921,224,409', ''],
      },
      {
        subject: '저장품',
        cardinalAfter: ['2,494,210,425', ''],
        cardinalBefore: ['1,841,234,925', ''],
      },
      {
        subject: 'II. 비유동자산',
        point: true,
        cardinalAfter: ['', '43,856,051,506'],
        cardinalBefore: ['', '46,956,031,161'],
      },
      {
        subject: '(1) 유형자산',
        point: true,
        cardinalAfter: ['', '33,835,469,179'],
        cardinalBefore: ['', '36,848,655,147'],
      },
      {
        subject: '토지',
        cardinalAfter: ['3,011,932,605', ''],
        cardinalBefore: ['3,011,932,605', ''],
      },
      {
        subject: '건물',
        cardinalAfter: ['13,317,401,877', ''],
        cardinalBefore: ['13,296,601,877', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['1,849,097,695', ''],
        cardinalBefore: ['1,516,527,763', ''],
      },
      {
        subject: '부대설비',
        cardinalAfter: ['8,849,286', ''],
        cardinalBefore: ['8,849,286', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['3,741,027', ''],
        cardinalBefore: ['3,454,052', ''],
      },
      {
        subject: '구축물',
        cardinalAfter: ['3,461,062,417', ''],
        cardinalBefore: ['3,350,490,970', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['454,267,688', ''],
        cardinalBefore: ['381,693,557', ''],
      },
      {
        subject: '기계장치',
        cardinalAfter: ['29,157,283,367', ''],
        cardinalBefore: ['28,695,458,762', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['17,823,551,453', ''],
        cardinalBefore: ['15,011,747,639', ''],
      },
      {
        subject: '차량운반구',
        cardinalAfter: ['48,383,568', ''],
        cardinalBefore: ['48,383,568', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['48,380,568', ''],
        cardinalBefore: ['46,603,234', ''],
      },
      {
        subject: '공기구비품',
        cardinalAfter: ['14,852,701,621', ''],
        cardinalBefore: ['16,862,760,970', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['9,909,984,131', ''],
        cardinalBefore: ['11,671,583,646', ''],
      },
      {
        subject: '손상차손누계액',
        cardinalAfter: ['35,623,000', ''],
        cardinalBefore: ['35,623,000', ''],
      },
      {
        subject: '건설중인자산',
        cardinalAfter: ['102,500,000', ''],
        cardinalBefore: ['241,410,000', ''],
      },
      {
        subject: '(2) 무형자산',
        point: true,
        cardinalAfter: ['', '8,023,739,505'],
        cardinalBefore: ['', '8,462,235,168'],
      },
      {
        subject: '소프트웨어',
        cardinalAfter: ['136,339,167', ''],
        cardinalBefore: ['27,731,917', ''],
      },
      {
        subject: '기타의무형자산',
        cardinalAfter: ['7,887,400,338', ''],
        cardinalBefore: ['8,434,503,251', ''],
      },
      {
        subject: '(3) 기타비유동자산',
        point: true,
        cardinalAfter: ['', '1,996,842,822'],
        cardinalBefore: ['', '1,645,140,846'],
      },
      {
        subject: '보증금',
        cardinalAfter: ['1,186,559,176', ''],
        cardinalBefore: ['943,559,176', ''],
      },
      {
        subject: '장기선급금',
        cardinalAfter: ['—', ''],
        cardinalBefore: ['506,000', ''],
      },
      {
        subject: '장기대여금',
        cardinalAfter: ['—', ''],
        cardinalBefore: ['35,000,000', ''],
      },
      {
        subject: '비유동이연법인세자산',
        cardinalAfter: ['810,283,646', ''],
        cardinalBefore: ['666,075,670', ''],
      },
      {
        subject: '자산총계',
        cardinalAfter: ['', '55,552,261,124'],
        cardinalBefore: ['', '62,532,002,833'],
      },
      {
        subject: '부채',
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 유동부채',
        point: true,
        cardinalAfter: ['', '40,972,997,864'],
        cardinalBefore: ['', '54,550,629,525'],
      },
      {
        subject: '단기차입금',
        cardinalAfter: ['27,881,528,741', ''],
        cardinalBefore: ['37,370,211,989', ''],
      },
      {
        subject: '매입채무',
        cardinalAfter: ['3,663,081,014', ''],
        cardinalBefore: ['5,951,192,162', ''],
      },
      {
        subject: '미지급금',
        cardinalAfter: ['3,379,141,313', ''],
        cardinalBefore: ['5,034,538,189', ''],
      },
      {
        subject: '미지급비용',
        cardinalAfter: ['1,425,347,964', ''],
        cardinalBefore: ['1,502,035,245', ''],
      },
      {
        subject: '선수금',
        cardinalAfter: ['594,139,945', ''],
        cardinalBefore: ['717,855,913', ''],
      },
      {
        subject: '예수금',
        cardinalAfter: ['105,618,501', ''],
        cardinalBefore: ['102,326,192', ''],
      },
      {
        subject: '부가세예수금',
        cardinalAfter: ['634,562,960', ''],
        cardinalBefore: ['622,271,790', ''],
      },
      {
        subject: '수입보증금',
        cardinalAfter: ['1,712,579,960', ''],
        cardinalBefore: ['1,742,943,287', ''],
      },
      {
        subject: '가맹보증금',
        cardinalAfter: ['178,000,000', ''],
        cardinalBefore: ['156,800,000', ''],
      },
      {
        subject: '당기법인세부채',
        cardinalAfter: ['1,398,997,466', ''],
        cardinalBefore: ['1,350,454,758', ''],
      },
      {
        subject: 'II. 비유동부채',
        point: true,
        cardinalAfter: ['', '3,394,636,842'],
        cardinalBefore: ['', '2,812,255,383'],
      },
      {
        subject: '퇴직급여충당부채',
        cardinalAfter: ['3,436,619,785', ''],
        cardinalBefore: ['2,853,725,321', ''],
      },
      {
        subject: '퇴직보험예치금',
        cardinalAfter: ['40,205,543', ''],
        cardinalBefore: ['39,692,538', ''],
      },
      {
        subject: '국민연금전환금',
        cardinalAfter: ['1,777,400', ''],
        cardinalBefore: ['1,777,400', ''],
      },
      {
        subject: '부채총계',
        cardinalAfter: ['', '44,367,634,706'],
        cardinalBefore: ['', '57,362,884,908'],
      },
      {
        subject: '자본',
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 자본금',
        point: true,
        cardinalAfter: ['', '6,778,760,000'],
        cardinalBefore: ['', '6,778,760,000'],
      },
      {
        subject: '보통주자본금',
        cardinalAfter: ['6,778,760,000', ''],
        cardinalBefore: ['6,778,760,000', ''],
      },
      {
        subject: 'II. 자본잉여금',
        point: true,
        cardinalAfter: ['', '6,751,882,994'],
        cardinalBefore: ['', '6,751,882,994'],
      },
      {
        subject: '주식발행초과금',
        cardinalAfter: ['6,751,882,994', ''],
        cardinalBefore: ['6,751,882,994', ''],
      },
      {
        subject: 'III. 결손금',
        point: true,
        cardinalAfter: ['', '2,346,016,576'],
        cardinalBefore: ['', '8,361,525,069'],
      },
      {
        subject: '임의적립금',
        cardinalAfter: ['88,000,000', ''],
        cardinalBefore: ['88,000,000', ''],
      },
      {
        subject: '미처리결손금',
        cardinalAfter: ['2,434,016,576', ''],
        cardinalBefore: ['8,449,525,069', ''],
      },
      {
        subject: '자본총계',
        cardinalAfter: ['', '11,184,626,418'],
        cardinalBefore: ['', '5,169,117,925'],
      },
      {
        subject: '부채 및 자본총계',
        cardinalAfter: ['', '55,552,261,124'],
        cardinalBefore: ['', '62,532,002,833'],
      },
    ],
  },
  {
    year: 2017,
    finance: 15,
    financialName: '삼정회계법인 대표이사 김교태',
    name: '조현근',
    data: [
      {
        subject: '자산',
        point: true,
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 유동자산',
        point: true,
        cardinalAfter: ['', '15,575,971,672'],
        cardinalBefore: ['', '11,261,341,201'],
      },
      {
        subject: '(1) 당좌자산',
        cardinalAfter: ['', '12,791,660,258'],
        cardinalBefore: ['', '7,510,129,030'],
      },
      {
        subject: '현금 및 현금성자산',
        cardinalAfter: ['3,330,731,924', ''],
        cardinalBefore: ['221,861,171', ''],
      },
      {
        subject: '매출채권',
        cardinalAfter: ['6,378,491,231', ''],
        cardinalBefore: ['6,576,294,437', ''],
      },
      {
        subject: '대손충당금',
        cardinalAfter: ['110,138,000', ''],
        cardinalBefore: ['121,556,875', ''],
      },
      {
        subject: '미수금',
        cardinalAfter: ['2,527,805,091', ''],
        cardinalBefore: ['677,931,871', ''],
      },
      {
        subject: '대손충당금',
        cardinalAfter: ['99,742,494', ''],
        cardinalBefore: ['99,742,494', ''],
      },
      {
        subject: '선급금',
        cardinalAfter: ['334,268,000', ''],
        cardinalBefore: ['173,158,364', ''],
      },
      {
        subject: '선급비용',
        cardinalAfter: ['78,552,502', ''],
        cardinalBefore: ['82,182,556', ''],
      },
      {
        subject: '유동이연법인세자산',
        cardinalAfter: ['351,692,004', ''],
        cardinalBefore: ['—', ''],
      },
      {
        subject: '(2) 재고자산',
        cardinalAfter: ['', '2,784,311,414'],
        cardinalBefore: ['', '3,751,212,171'],
      },
      {
        subject: '상품',
        cardinalAfter: ['21,852,080', ''],
        cardinalBefore: ['24,518,305', ''],
      },
      {
        subject: '제품',
        cardinalAfter: ['921,224,409', ''],
        cardinalBefore: ['1,707,090,413', ''],
      },
      {
        subject: '저장품',
        cardinalAfter: ['1,841,234,925', ''],
        cardinalBefore: ['2,019,603,453', ''],
      },
      {
        subject: 'II. 비유동자산',
        point: true,
        cardinalAfter: ['', '46,956,031,161'],
        cardinalBefore: ['', '50,693,172,606'],
      },
      {
        subject: '(1) 유형자산',
        point: true,
        cardinalAfter: ['', '36,848,655,147'],
        cardinalBefore: ['', '39,913,572,247'],
      },
      {
        subject: '토지',
        cardinalAfter: ['3,011,932,605', ''],
        cardinalBefore: ['3,011,932,605', ''],
      },
      {
        subject: '건물',
        cardinalAfter: ['13,296,601,877', ''],
        cardinalBefore: ['13,247,341,877', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['1,516,527,763', ''],
        cardinalBefore: ['1,184,840,825', ''],
      },
      {
        subject: '부대설비',
        cardinalAfter: ['8,849,286', ''],
        cardinalBefore: ['8,849,286', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['3,454,052', ''],
        cardinalBefore: ['3,167,077', ''],
      },
      {
        subject: '구축물',
        cardinalAfter: ['3,350,490,970', ''],
        cardinalBefore: ['3,275,290,970', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['381,693,557', ''],
        cardinalBefore: ['297,571,536', ''],
      },
      {
        subject: '기계장치',
        cardinalAfter: ['28,695,458,762', ''],
        cardinalBefore: ['28,549,658,762', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['15,011,747,639', ''],
        cardinalBefore: ['12,240,075,015', ''],
      },
      {
        subject: '차량운반구',
        cardinalAfter: ['48,383,568', ''],
        cardinalBefore: ['48,383,568', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['46,603,234', ''],
        cardinalBefore: ['44,663,234', ''],
      },
      {
        subject: '공기구비품',
        cardinalAfter: ['16,862,760,970', ''],
        cardinalBefore: ['16,999,222,546', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['11,671,583,646', ''],
        cardinalBefore: ['11,291,217,555', ''],
      },
      {
        subject: '손상차손누계액',
        cardinalAfter: ['35,623,000', ''],
        cardinalBefore: ['165,572,125', ''],
      },
      {
        subject: '건설중인자산',
        cardinalAfter: ['241,410,000', ''],
        cardinalBefore: ['—', ''],
      },
      {
        subject: '(2) 무형자산',
        point: true,
        cardinalAfter: ['', '8,462,235,168'],
        cardinalBefore: ['', '9,075,885,081'],
      },
      {
        subject: '소프트웨어',
        cardinalAfter: ['27,731,917', ''],
        cardinalBefore: ['94,278,917', ''],
      },
      {
        subject: '기타의무형자산',
        cardinalAfter: ['8,434,503,251', ''],
        cardinalBefore: ['8,981,606,164', ''],
      },
      {
        subject: '(3) 기타비유동자산',
        point: true,
        cardinalAfter: ['', '1,645,140,846'],
        cardinalBefore: ['', '1,703,715,278'],
      },
      {
        subject: '보증금',
        cardinalAfter: ['943,559,176', ''],
        cardinalBefore: ['1,701,209,278', ''],
      },
      {
        subject: '장기선급금',
        cardinalAfter: ['506,000', ''],
        cardinalBefore: ['2,506,000', ''],
      },
      {
        subject: '장기대여금',
        cardinalAfter: ['35,000,000', ''],
        cardinalBefore: ['—', ''],
      },
      {
        subject: '비유동이연법인세자산',
        cardinalAfter: ['666,075,670', ''],
        cardinalBefore: ['—', ''],
      },
      {
        subject: '자산총계',
        cardinalAfter: ['', '62,532,002,833'],
        cardinalBefore: ['', '61,954,513,807'],
      },
      {
        subject: '부채',
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 유동부채',
        point: true,
        cardinalAfter: ['', '54,550,629,525'],
        cardinalBefore: ['', '60,031,549,756'],
      },
      {
        subject: '단기차입금',
        cardinalAfter: ['37,370,211,989', ''],
        cardinalBefore: ['41,008,012,313', ''],
      },
      {
        subject: '매입채무',
        cardinalAfter: ['5,951,192,162', ''],
        cardinalBefore: ['6,011,639,690', ''],
      },
      {
        subject: '미지급금',
        cardinalAfter: ['5,034,538,189', ''],
        cardinalBefore: ['7,259,504,048', ''],
      },
      {
        subject: '미지급비용',
        cardinalAfter: ['1,502,035,245', ''],
        cardinalBefore: ['1,708,072,038', ''],
      },
      {
        subject: '선수금',
        cardinalAfter: ['717,855,913', ''],
        cardinalBefore: ['908,260,333', ''],
      },
      {
        subject: '예수금',
        cardinalAfter: ['102,326,192', ''],
        cardinalBefore: ['138,993,255', ''],
      },
      {
        subject: '부가세예수금',
        cardinalAfter: ['662,271,790', ''],
        cardinalBefore: ['410,194,720', ''],
      },
      {
        subject: '수입보증금',
        cardinalAfter: ['1,742,943,287', ''],
        cardinalBefore: ['1,777,716,468', ''],
      },
      {
        subject: '가맹보증금',
        cardinalAfter: ['156,800,000', ''],
        cardinalBefore: ['89,812,852', ''],
      },
      {
        subject: '당기법인세부채',
        cardinalAfter: ['1,350,454,758', ''],
        cardinalBefore: ['719,344,039', ''],
      },
      {
        subject: 'II. 비유동부채',
        point: true,
        cardinalAfter: ['', '2,812,255,383'],
        cardinalBefore: ['', '2,208,868,347'],
      },
      {
        subject: '퇴직급여충당부채',
        cardinalAfter: ['2,853,725,321', ''],
        cardinalBefore: ['2,249,831,825', ''],
      },
      {
        subject: '퇴직보험예치금',
        cardinalAfter: ['39,692,538', ''],
        cardinalBefore: ['39,186,078', ''],
      },
      {
        subject: '국민연금전환금',
        cardinalAfter: ['', '1,777,400'],
        cardinalBefore: ['', '1,777,400'],
      },
      {
        subject: '부채총계',
        cardinalAfter: ['', '57,362,884,908'],
        cardinalBefore: ['', '62,240,418,103'],
      },
      {
        subject: '자본',
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 자본금',
        point: true,
        cardinalAfter: ['', '6,778,760,000'],
        cardinalBefore: ['', '6,778,760,000'],
      },
      {
        subject: '보통주자본금',
        cardinalAfter: ['6,778,760,000', ''],
        cardinalBefore: ['6,778,760,000', ''],
      },
      {
        subject: 'II. 자본잉여금',
        point: true,
        cardinalAfter: ['', '6,751,882,994'],
        cardinalBefore: ['', '6,751,882,994'],
      },
      {
        subject: '주식발행초과금',
        cardinalAfter: ['6,751,882,994', ''],
        cardinalBefore: ['6,751,882,994', ''],
      },
      {
        subject: 'III. 결손금',
        point: true,
        cardinalAfter: ['', '8,361,525,069'],
        cardinalBefore: ['', '13,816,547,290'],
      },
      {
        subject: '임의적립금',
        cardinalAfter: ['88,000,000', ''],
        cardinalBefore: ['88,000,000', ''],
      },
      {
        subject: '미처리결손금',
        cardinalAfter: ['8,449,525,069', ''],
        cardinalBefore: ['13,904,547,290', ''],
      },
      {
        subject: '자본총계',
        cardinalAfter: ['', '5,169,117,925'],
        cardinalBefore: ['', '285,904,296'],
      },
      {
        subject: '부채 및 자본총계',
        cardinalAfter: ['', '62,532,002,833'],
        cardinalBefore: ['', '61,954,513,807'],
      },
    ],
  },
  {
    year: 2016,
    finance: 14,
    financialName: '삼정회계법인 대표이사 김교태',
    name: '정희련',
    data: [
      {
        subject: '자산',
        point: true,
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 유동자산',
        point: true,
        cardinalAfter: ['', '11,261,341,201'],
        cardinalBefore: ['', '10,281,151,431'],
      },
      {
        subject: '(1) 당좌자산',
        cardinalAfter: ['', '7,510,129,030'],
        cardinalBefore: ['', '8,137,744,118'],
      },
      {
        subject: '현금 및 현금성자산',
        cardinalAfter: ['221,861,171', ''],
        cardinalBefore: ['154,526,157', ''],
      },
      {
        subject: '매출채권',
        cardinalAfter: ['6,576,294,437', ''],
        cardinalBefore: ['6,016,977,149', ''],
      },
      {
        subject: '대손충당금',
        cardinalAfter: ['121,556,875', ''],
        cardinalBefore: ['55,139,000', ''],
      },
      {
        subject: '미수금',
        cardinalAfter: ['677,931,871', ''],
        cardinalBefore: ['1,877,335,784', ''],
      },
      {
        subject: '대손충당금',
        cardinalAfter: ['99,742,494', ''],
        cardinalBefore: ['38,500,000', ''],
      },
      {
        subject: '선급금',
        cardinalAfter: ['173,158,364', ''],
        cardinalBefore: ['160,638,500', ''],
      },
      {
        subject: '선급비용',
        cardinalAfter: ['82,182,556', ''],
        cardinalBefore: ['21,905,528', ''],
      },
      {
        subject: '(2) 재고자산',
        cardinalAfter: ['', '3,751,212,171'],
        cardinalBefore: ['', '2,143,407,313'],
      },
      {
        subject: '상품',
        cardinalAfter: ['24,518,305', ''],
        cardinalBefore: ['22,305,101', ''],
      },
      {
        subject: '제품',
        cardinalAfter: ['1,707,090,413', ''],
        cardinalBefore: ['699,346,220', ''],
      },
      {
        subject: '저장품',
        cardinalAfter: ['2,019,603,453', ''],
        cardinalBefore: ['1,421,755,992', ''],
      },
      {
        subject: 'II. 비유동자산',
        point: true,
        cardinalAfter: ['', '50,693,172,606'],
        cardinalBefore: ['', '53,219,649,913'],
      },
      {
        subject: '(1) 유형자산',
        point: true,
        cardinalAfter: ['', '39,913,572,247'],
        cardinalBefore: ['', '42,110,709,454'],
      },
      {
        subject: '토지',
        cardinalAfter: ['3,011,932,605', ''],
        cardinalBefore: ['3,011,932,605', ''],
      },
      {
        subject: '건물',
        cardinalAfter: ['13,247,341,877', ''],
        cardinalBefore: ['13,191,841,877', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['1,184,840,825', ''],
        cardinalBefore: ['853,998,769', ''],
      },
      {
        subject: '부대설비',
        cardinalAfter: ['8,849,286', ''],
        cardinalBefore: ['10,681,855', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['3,167,077', ''],
        cardinalBefore: ['3,246,233', ''],
      },
      {
        subject: '구축물',
        cardinalAfter: ['3,275,290,970', ''],
        cardinalBefore: ['3,085,820,970', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['297,571,536', ''],
        cardinalBefore: ['214,525,226', ''],
      },
      {
        subject: '기계장치',
        cardinalAfter: ['28,549,658,762', ''],
        cardinalBefore: ['27,067,546,215', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['12,240,075,015', ''],
        cardinalBefore: ['9,665,738,699', ''],
      },
      {
        subject: '차량운반구',
        cardinalAfter: ['48,383,568', ''],
        cardinalBefore: ['48,383,568', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['44,663,234', ''],
        cardinalBefore: ['42,723,234', ''],
      },
      {
        subject: '공기구비품',
        cardinalAfter: ['16,999,222,546', ''],
        cardinalBefore: ['16,668,235,653', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['11,291,217,555', ''],
        cardinalBefore: ['10,268,501,128', ''],
      },
      {
        subject: '손상차손누계액',
        cardinalAfter: ['165,572,125', ''],
        cardinalBefore: ['-', ''],
      },
      {
        subject: '건설중인자산',
        cardinalAfter: ['-', ''],
        cardinalBefore: ['75,000,000', ''],
      },
      {
        subject: '(2) 무형자산',
        point: true,
        cardinalAfter: ['', '9,075,885,081'],
        cardinalBefore: ['', '9,689,823,994'],
      },
      {
        subject: '소프트웨어',
        cardinalAfter: ['94,278,917', ''],
        cardinalBefore: ['161.114.917', ''],
      },
      {
        subject: '기타의무형자산',
        cardinalAfter: ['8,981,606,164', ''],
        cardinalBefore: ['9,528,709,077', ''],
      },
      {
        subject: '(3) 기타비유동자산',
        point: true,
        cardinalAfter: ['', '1,701,209,278'],
        cardinalBefore: ['', '1,414,602,465'],
      },
      {
        subject: '보증금',
        cardinalAfter: ['1,701,209,278', ''],
        cardinalBefore: ['1,414,602,465', ''],
      },
      {
        subject: '장기선급금',
        cardinalAfter: ['2,506,000', ''],
        cardinalBefore: ['4,514,000', ''],
      },
      {
        subject: '자산총계',
        cardinalAfter: ['', '61,954,513,807'],
        cardinalBefore: ['', '63,500,501,344'],
      },
      {
        subject: '부채',
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 유동부채',
        point: true,
        cardinalAfter: ['', '60,031,549,756'],
        cardinalBefore: ['', '66,888,605,678'],
      },
      {
        subject: '단기차입금',
        cardinalAfter: ['41,008,012,313', ''],
        cardinalBefore: ['55,008,768,825', ''],
      },
      {
        subject: '매입채무',
        cardinalAfter: ['6,011,639,690', ''],
        cardinalBefore: ['2,735,977,023', ''],
      },
      {
        subject: '미지급금',
        cardinalAfter: ['7,259,504,048', ''],
        cardinalBefore: ['4,130,861,279', ''],
      },
      {
        subject: '미지급비용',
        cardinalAfter: ['1,708,072,038', ''],
        cardinalBefore: ['1,665,731,001', ''],
      },
      {
        subject: '선수금',
        cardinalAfter: ['908,260,333', ''],
        cardinalBefore: ['587,470,838', ''],
      },
      {
        subject: '예수금',
        cardinalAfter: ['138,993,255', ''],
        cardinalBefore: ['80,681,326', ''],
      },
      {
        subject: '부가세예수금',
        cardinalAfter: ['410,194,720', ''],
        cardinalBefore: ['517,366,930', ''],
      },
      {
        subject: '수입보증금',
        cardinalAfter: ['1,777,716,468', ''],
        cardinalBefore: ['1,816,209,974', ''],
      },
      {
        subject: '가맹보증금',
        cardinalAfter: ['89,812,852', ''],
        cardinalBefore: ['66,812,852', ''],
      },
      {
        subject: '당기법인세부채',
        cardinalAfter: ['719,344,039', ''],
        cardinalBefore: ['278,725,630', ''],
      },
      {
        subject: 'II. 비유동부채',
        point: true,
        cardinalAfter: ['', '2,208,868,347'],
        cardinalBefore: ['', '2,264,297,272'],
      },
      {
        subject: '퇴직급여충당부채',
        cardinalAfter: ['2,249,831,825', ''],
        cardinalBefore: ['2,306,652,689', ''],
      },
      {
        subject: '퇴직보험예치금',
        cardinalAfter: ['39,186,078', ''],
        cardinalBefore: ['40,578,017', ''],
      },
      {
        subject: '국민연금전환금',
        cardinalAfter: ['1,777,400', ''],
        cardinalBefore: ['1,777,400', ''],
      },
      {
        subject: '부채총계',
        cardinalAfter: ['', '62,240,418,103'],
        cardinalBefore: ['', '69,152,902,950'],
      },
      {
        subject: '자본',
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 자본금',
        point: true,
        cardinalAfter: ['', '6,778,760,000'],
        cardinalBefore: ['', '6,778,760,000'],
      },
      {
        subject: '보통주자본금',
        cardinalAfter: ['6,778,760,000', ''],
        cardinalBefore: ['6,778,760,000', ''],
      },
      {
        subject: 'II. 자본잉여금',
        point: true,
        cardinalAfter: ['', '6,751,882,994'],
        cardinalBefore: ['', '6,751,882,994'],
      },
      {
        subject: '주식발행초과금',
        cardinalAfter: ['6,751,882,994', ''],
        cardinalBefore: ['6,751,882,994', ''],
      },
      {
        subject: 'III. 결손금',
        point: true,
        cardinalAfter: ['', '13,816,547,290'],
        cardinalBefore: ['', '19,182,744,600'],
      },
      {
        subject: '임의적립금',
        cardinalAfter: ['88,000,000', ''],
        cardinalBefore: ['88,000,000', ''],
      },
      {
        subject: '미처리결손금',
        cardinalAfter: ['13,904,547,290', ''],
        cardinalBefore: ['19,270,744,600', ''],
      },
      {
        subject: '자본총계',
        cardinalAfter: ['', '285,904,296'],
        cardinalBefore: ['', '5,652,101,606'],
      },
      {
        subject: '부채 및 자본총계',
        cardinalAfter: ['', '61,954,513,807'],
        cardinalBefore: ['', '63,500,801,344'],
      },
    ],
  },
  {
    year: 2015,
    finance: 13,
    financialName: '삼정회계법인 대표이사 김교태',
    name: '정희련',
    data: [
      {
        subject: '자산',
        point: true,
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 유동자산',
        point: true,
        cardinalAfter: ['', '10,281,151,431'],
        cardinalBefore: ['', '8,083,493,864'],
      },
      {
        subject: '(1) 당좌자산',
        cardinalAfter: ['', '8,137,744,118'],
        cardinalBefore: ['', '6,122,860,697'],
      },
      {
        subject: '현금 및 현금성자산',
        cardinalAfter: ['154,526,157', ''],
        cardinalBefore: ['153,427,546', ''],
      },
      {
        subject: '매출채권',
        cardinalAfter: ['6,016,977,149', ''],
        cardinalBefore: ['5,232,942,035', ''],
      },
      {
        subject: '대손충당금',
        cardinalAfter: ['55,139,000', ''],
        cardinalBefore: ['165,264,000', ''],
      },
      {
        subject: '미수금',
        cardinalAfter: ['1,877,335,784', ''],
        cardinalBefore: ['621,008,156', ''],
      },
      {
        subject: '대손충당금',
        cardinalAfter: ['38,500,000', ''],
        cardinalBefore: ['-', ''],
      },
      {
        subject: '당기법인세자산',
        cardinalAfter: ['-', ''],
        cardinalBefore: ['216,760', ''],
      },
      {
        subject: '선급금',
        cardinalAfter: ['160,638,500', ''],
        cardinalBefore: ['220,562,438', ''],
      },
      {
        subject: '선급비용',
        cardinalAfter: ['21,905,528', ''],
        cardinalBefore: ['59,967,762', ''],
      },
      {
        subject: '(2) 재고자산',
        cardinalAfter: ['', '2,143,407,313'],
        cardinalBefore: ['', '1,960,633,167'],
      },
      {
        subject: '상품',
        cardinalAfter: ['22,305,101', ''],
        cardinalBefore: ['32,086,233', ''],
      },
      {
        subject: '제품',
        cardinalAfter: ['699,346,220', ''],
        cardinalBefore: ['676,813,493', ''],
      },
      {
        subject: '저장품',
        cardinalAfter: ['1,421,755,992', ''],
        cardinalBefore: ['1,251,733,441', ''],
      },
      {
        subject: 'II. 비유동자산',
        point: true,
        cardinalAfter: ['', '53,219,649,913'],
        cardinalBefore: ['', '57,408,483,135'],
      },
      {
        subject: '(1) 유형자산',
        point: true,
        cardinalAfter: ['', '42,110,709,454'],
        cardinalBefore: ['', '45,110,998,884'],
      },
      {
        subject: '토지',
        cardinalAfter: ['3,011,932,605', ''],
        cardinalBefore: ['3,011,932,605', ''],
      },
      {
        subject: '건물',
        cardinalAfter: ['13,191,841,877', ''],
        cardinalBefore: ['13,192,681,544', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['853,998,769', ''],
        cardinalBefore: ['523,406,993', ''],
      },
      {
        subject: '부대설비',
        cardinalAfter: ['10,681,855', ''],
        cardinalBefore: ['12,247,723', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['3,246,233', ''],
        cardinalBefore: ['3,775,445', ''],
      },
      {
        subject: '구축물',
        cardinalAfter: ['3,085,820,970', ''],
        cardinalBefore: ['2,865,129,970', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['214,525,226', ''],
        cardinalBefore: ['142,625,938', ''],
      },
      {
        subject: '기계장치',
        cardinalAfter: ['27,067,546,215', ''],
        cardinalBefore: ['26,508,275,982', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['9,665,738,699', ''],
        cardinalBefore: ['7,195,331,547', ''],
      },
      {
        subject: '차량운반구',
        cardinalAfter: ['48,383,568', ''],
        cardinalBefore: ['48,383,568', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['42,723,234', ''],
        cardinalBefore: ['38,510,726', ''],
      },
      {
        subject: '공기구비품',
        cardinalAfter: ['16,668,235,653', ''],
        cardinalBefore: ['16,482,069,827', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['10,268,501,128', ''],
        cardinalBefore: ['9,106,071,686', ''],
      },
      {
        subject: '건설중인자산',
        cardinalAfter: ['75,000,000', ''],
        cardinalBefore: ['-', ''],
      },
      {
        subject: '(2) 무형자산',
        point: true,
        cardinalAfter: ['', '9,689,823,994'],
        cardinalBefore: ['', '10,346,376,075'],
      },
      {
        subject: '소프트웨어',
        cardinalAfter: ['161,114,917', ''],
        cardinalBefore: ['270,564,085', ''],
      },
      {
        subject: '기타의무형자산',
        cardinalAfter: ['9,528,709,077', ''],
        cardinalBefore: ['10,075,811,990', ''],
      },
      {
        subject: '(3) 기타비유동자산',
        point: true,
        cardinalAfter: ['', '1,419,116,465'],
        cardinalBefore: ['', '1,951,108,176'],
      },
      {
        subject: '보증금',
        cardinalAfter: ['1,414,602,465', ''],
        cardinalBefore: ['1,779,302,500', ''],
      },
      {
        subject: '장기선급금',
        cardinalAfter: ['4,514,000', ''],
        cardinalBefore: ['77,014,000', ''],
      },
      {
        subject: '장기선급비용',
        cardinalAfter: ['-', ''],
        cardinalBefore: ['94,791,676', ''],
      },
      {
        subject: '자산총계',
        cardinalAfter: ['', '63,500,801,344'],
        cardinalBefore: ['', '65,491,976,999'],
      },
      {
        subject: '부채',
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 유동부채',
        point: true,
        cardinalAfter: ['', '66,888,605,678'],
        cardinalBefore: ['', '75,303,526,050'],
      },
      {
        subject: '단기차입금',
        cardinalAfter: ['55,008,768,825', ''],
        cardinalBefore: ['65,156,849,202', ''],
      },
      {
        subject: '매입채무',
        cardinalAfter: ['2,735,977,023', ''],
        cardinalBefore: ['2,229,447,231', ''],
      },
      {
        subject: '미지급금',
        cardinalAfter: ['4,130,861,279', ''],
        cardinalBefore: ['3,379,593,365', ''],
      },
      {
        subject: '미지급비용',
        cardinalAfter: ['1,665,731,001', ''],
        cardinalBefore: ['1,292,976,945', ''],
      },
      {
        subject: '선수금',
        cardinalAfter: ['587,470,838', ''],
        cardinalBefore: ['629,499,369', ''],
      },
      {
        subject: '예수금',
        cardinalAfter: ['80,681,326', ''],
        cardinalBefore: ['116,616,298', ''],
      },
      {
        subject: '부가세예수금',
        cardinalAfter: ['517,366,930', ''],
        cardinalBefore: ['528,341,900', ''],
      },
      {
        subject: '수입보증금',
        cardinalAfter: ['1,816,209,974', ''],
        cardinalBefore: ['1,839,491,404', ''],
      },
      {
        subject: '가맹보증금',
        cardinalAfter: ['66,812,852', ''],
        cardinalBefore: ['42,012,852', ''],
      },
      {
        subject: '소송충당부채',
        cardinalAfter: ['-', ''],
        cardinalBefore: ['88,697,484', ''],
      },
      {
        subject: '당기법인세부채',
        cardinalAfter: ['278,725,630', ''],
        cardinalBefore: ['-', ''],
      },
      {
        subject: 'II. 비유동부채',
        point: true,
        cardinalAfter: ['', '2,264,297,272'],
        cardinalBefore: ['', '1,920,925,892'],
      },
      {
        subject: '퇴직급여충당부채',
        cardinalAfter: ['2,306,652,689', ''],
        cardinalBefore: ['1,962,727,057', ''],
      },
      {
        subject: '퇴직보험예치금',
        cardinalAfter: ['40,578,017', ''],
        cardinalBefore: ['40,023,765', ''],
      },
      {
        subject: '국민연금전환금',
        cardinalAfter: ['1,777,400', ''],
        cardinalBefore: ['1,777,400', ''],
      },
      {
        subject: '부채총계',
        cardinalAfter: ['', '69,152,902,950'],
        cardinalBefore: ['', '77,224,451,942'],
      },
      {
        subject: '자본',
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 자본금',
        point: true,
        cardinalAfter: ['', '6,778,760,000'],
        cardinalBefore: ['', '6,778,760,000'],
      },
      {
        subject: '보통주자본금',
        cardinalAfter: ['6,778,760,000', ''],
        cardinalBefore: ['6,778,760,000', ''],
      },
      {
        subject: 'II. 자본잉여금',
        point: true,
        cardinalAfter: ['', '6,751,882,994'],
        cardinalBefore: ['', '6,751,882,994'],
      },
      {
        subject: '주식발행초과금',
        cardinalAfter: ['6,751,882,994', ''],
        cardinalBefore: ['6,751,882,994', ''],
      },
      {
        subject: 'III. 결손금',
        point: true,
        cardinalAfter: ['', '19,182,744,600'],
        cardinalBefore: ['', '25,263,117,937'],
      },
      {
        subject: '임의적립금',
        cardinalAfter: ['88,000,000', ''],
        cardinalBefore: ['88,000,000', ''],
      },
      {
        subject: '미처리결손금',
        cardinalAfter: ['19,270,744,600', ''],
        cardinalBefore: ['25,351,117,937', ''],
      },
      {
        subject: '자본총계',
        cardinalAfter: ['', '5,652,101,606'],
        cardinalBefore: ['', '11,732,474,943'],
      },
      {
        subject: '부채 및 자본총계',
        cardinalAfter: ['', '63,500,801,344'],
        cardinalBefore: ['', '65,491,976,999'],
      },
    ],
  },
  {
    year: 2014,
    finance: 12,
    financialName: '삼정회계법인 대표이사 김교태',
    name: '정희련',
    data: [
      {
        subject: '자산',
        point: true,
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 유동자산',
        point: true,
        cardinalAfter: ['', '8,083,493,864'],
        cardinalBefore: ['', '8,235,050,750'],
      },
      {
        subject: '(1) 당좌자산',
        cardinalAfter: ['', '6,122,860,697'],
        cardinalBefore: ['', '6,493,896,123'],
      },
      {
        subject: '현금 및 현금성자산',
        cardinalAfter: ['153,427,546', ''],
        cardinalBefore: ['1,582,155,706', ''],
      },
      {
        subject: '매출채권',
        cardinalAfter: ['5,232,942,035', ''],
        cardinalBefore: ['4,135,206,930', ''],
      },
      {
        subject: '대손충당금',
        cardinalAfter: ['165,264,000', ''],
        cardinalBefore: ['199,178,000', ''],
      },
      {
        subject: '미수금',
        cardinalAfter: ['621,008,156', ''],
        cardinalBefore: ['781,041,912', ''],
      },
      {
        subject: '미수수익',
        cardinalAfter: ['-', ''],
        cardinalBefore: ['260,567', ''],
      },
      {
        subject: '당기법인세자산',
        cardinalAfter: ['216,760', ''],
        cardinalBefore: ['93,670', ''],
      },
      {
        subject: '부가세대급금',
        cardinalAfter: ['-', ''],
        cardinalBefore: ['-', ''],
      },
      {
        subject: '선급금',
        cardinalAfter: ['220,562,438', ''],
        cardinalBefore: ['133,555,901', ''],
      },
      {
        subject: '선급비용',
        cardinalAfter: ['59,967,762', ''],
        cardinalBefore: ['60,759,437', ''],
      },
      {
        subject: '(2) 재고자산',
        cardinalAfter: ['', '1,960,633,167'],
        cardinalBefore: ['', '1,741,154,627'],
      },
      {
        subject: '상품',
        cardinalAfter: ['32,086,233', ''],
        cardinalBefore: ['28,640,971', ''],
      },
      {
        subject: '제품',
        cardinalAfter: ['676,813,493', ''],
        cardinalBefore: ['533,094,607', ''],
      },
      {
        subject: '저장품',
        cardinalAfter: ['1,251,733,441', ''],
        cardinalBefore: ['1,179,419,049', ''],
      },
      {
        subject: 'II. 비유동자산',
        point: true,
        cardinalAfter: ['', '57,408,483,135'],
        cardinalBefore: ['', '62,867,744,438'],
      },
      {
        subject: '(1) 유형자산',
        point: true,
        cardinalAfter: ['', '45,110,998,884'],
        cardinalBefore: ['', '49,678,373,169'],
      },
      {
        subject: '토지',
        cardinalAfter: ['3,011,932,605', ''],
        cardinalBefore: ['3,011,932,605', ''],
      },
      {
        subject: '건물',
        cardinalAfter: ['13,192,681,544', ''],
        cardinalBefore: ['13,106,781,544', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['523,406,993', ''],
        cardinalBefore: ['191,861,710', ''],
      },
      {
        subject: '부대설비',
        cardinalAfter: ['12,247,723', ''],
        cardinalBefore: ['12,247,723', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['3,775,445', ''],
        cardinalBefore: ['3,366,012', ''],
      },
      {
        subject: '구축물',
        cardinalAfter: ['2,865,129,970', ''],
        cardinalBefore: ['2,824,826,397', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['142,625,938', ''],
        cardinalBefore: ['68,098,253', ''],
      },
      {
        subject: '기계장치',
        cardinalAfter: ['26,508,275,982', ''],
        cardinalBefore: ['26,726,572,868', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['7,195,331,547', ''],
        cardinalBefore: ['5,851,060,463', ''],
      },
      {
        subject: '차량운반구',
        cardinalAfter: ['48,383,568', ''],
        cardinalBefore: ['48,383,568', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['38,510,726', ''],
        cardinalBefore: ['28,834,014', ''],
      },
      {
        subject: '공기구비품',
        cardinalAfter: ['16,482,069,827', ''],
        cardinalBefore: ['19,167,097,020', ''],
      },
      {
        subject: '감가상각누계액',
        cardinalAfter: ['9,106,071,686', ''],
        cardinalBefore: ['10,655,428,502', ''],
      },
      {
        subject: '건설중인자산',
        cardinalAfter: ['-', ''],
        cardinalBefore: ['1,579,180,398', ''],
      },
      {
        subject: '(2) 무형자산',
        point: true,
        cardinalAfter: ['', '10,346,376,075'],
        cardinalBefore: ['', '11,024,433,988'],
      },
      {
        subject: '소프트웨어',
        cardinalAfter: ['270,564,085', ''],
        cardinalBefore: ['401,519,085', ''],
      },
      {
        subject: '기타의무형자산',
        cardinalAfter: ['10,075,811,990', ''],
        cardinalBefore: ['10,622,914,903', ''],
      },
      {
        subject: '(3) 기타비유동자산',
        point: true,
        cardinalAfter: ['', '1,951,108,176'],
        cardinalBefore: ['', '2,164,937,281'],
      },
      {
        subject: '보증금',
        cardinalAfter: ['1,779,302,500', ''],
        cardinalBefore: ['1,774,302,500', ''],
      },
      {
        subject: '장기선급금',
        cardinalAfter: ['77,014,000', ''],
        cardinalBefore: ['255,566,000', ''],
      },
      {
        subject: '장기선급비용',
        cardinalAfter: ['94,791,676', ''],
        cardinalBefore: ['135,068,781', ''],
      },
      {
        subject: '자산총계',
        cardinalAfter: ['', '65,491,976,999'],
        cardinalBefore: ['', '71,102,795,188'],
      },
      {
        subject: '부채',
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 유동부채',
        point: true,
        cardinalAfter: ['', '75,303,526,050'],
        cardinalBefore: ['', '81,767,248,075'],
      },
      {
        subject: '단기차입금',
        cardinalAfter: ['65,156,849,202', ''],
        cardinalBefore: ['43,666,791,988', ''],
      },
      {
        subject: '유동성장기차입금',
        cardinalAfter: ['-', ''],
        cardinalBefore: ['25,000,000,000', ''],
      },
      {
        subject: '매입채무',
        cardinalAfter: ['2,229,447,231', ''],
        cardinalBefore: ['1,976,583,796', ''],
      },
      {
        subject: '미지급금',
        cardinalAfter: ['3,379,593,365', ''],
        cardinalBefore: ['7,266,720,458', ''],
      },
      {
        subject: '미지급비용',
        cardinalAfter: ['1,292,976,945', ''],
        cardinalBefore: ['1,245,533,954', ''],
      },
      {
        subject: '선수금',
        cardinalAfter: ['629,499,369', ''],
        cardinalBefore: ['323,525,480', ''],
      },
      {
        subject: '예수금',
        cardinalAfter: ['116,616,298', ''],
        cardinalBefore: ['74,888,893', ''],
      },
      {
        subject: '부가세예수금',
        cardinalAfter: ['528,341,900', ''],
        cardinalBefore: ['340,570,750', ''],
      },
      {
        subject: '수입보증금',
        cardinalAfter: ['1,839,491,404', ''],
        cardinalBefore: ['1,790,619,904', ''],
      },
      {
        subject: '가맹보증금',
        cardinalAfter: ['42,012,852', ''],
        cardinalBefore: ['82,012,852', ''],
      },
      {
        subject: '소송충당부채',
        cardinalAfter: ['88,697,484', ''],
        cardinalBefore: ['-', ''],
      },
      {
        subject: 'II. 비유동부채',
        point: true,
        cardinalAfter: ['', '1,920,925,892'],
        cardinalBefore: ['', '1,730,956,272'],
      },
      {
        subject: '퇴직급여충당부채',
        cardinalAfter: ['1,962,727,057', ''],
        cardinalBefore: ['1,771,880,791', ''],
      },
      {
        subject: '퇴직보험예치금',
        cardinalAfter: ['40,023,765', ''],
        cardinalBefore: ['39,147,119', ''],
      },
      {
        subject: '국민연금전환금',
        cardinalAfter: ['1,777,400', ''],
        cardinalBefore: ['1,777,400', ''],
      },
      {
        subject: '부채총계',
        cardinalAfter: ['', '77,224,451,942'],
        cardinalBefore: ['', '83,498,204,347'],
      },
      {
        subject: '자본',
        cardinalAfter: ['', ''],
        cardinalBefore: ['', ''],
      },
      {
        subject: 'I. 자본금',
        point: true,
        cardinalAfter: ['', '6,778,760,000'],
        cardinalBefore: ['', '6,778,760,000'],
      },
      {
        subject: '보통주자본금',
        cardinalAfter: ['6,778,760,000', ''],
        cardinalBefore: ['6,778,760,000', ''],
      },
      {
        subject: 'II. 자본잉여금',
        point: true,
        cardinalAfter: ['', '6,751,882,994'],
        cardinalBefore: ['', '6,751,882,994'],
      },
      {
        subject: '주식발행초과금',
        cardinalAfter: ['6,751,882,994', ''],
        cardinalBefore: ['6,751,882,994', ''],
      },
      {
        subject: 'III. 결손금',
        point: true,
        cardinalAfter: ['', '25,263,117,937'],
        cardinalBefore: ['', '25,926,052,153'],
      },
      {
        subject: '임의적립금',
        cardinalAfter: ['88,000,000', ''],
        cardinalBefore: ['88,000,000', ''],
      },
      {
        subject: '미처리결손금',
        cardinalAfter: ['25,351,117,937', ''],
        cardinalBefore: ['26,014,052,153', ''],
      },
      {
        subject: '자본총계',
        cardinalAfter: ['', '11,732,474,943'],
        cardinalBefore: ['', '12,395,409,159'],
      },
      {
        subject: '부채 및 자본총계',
        cardinalAfter: ['', '65,491,976,999'],
        cardinalBefore: ['', '71,102,795,188'],
      },
    ],
  },
];

import { useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import gsap from 'gsap';
import { useMediaQuery } from 'react-responsive';

import styles from './S02.module.scss';

const cx = classNames.bind(styles);

const Section = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 734px)' });

  const containerRef = useRef();
  const wrapper1Ref = useRef();
  const wrapper2Ref = useRef();
  const wrapper3Ref = useRef();

  useEffect(() => {
    if (isMobile) {
      return;
    }

    gsap
      .timeline({
        scrollTrigger: {
          trigger: containerRef.current,
          scrub: true,
          pin: true,
          start: 'bottom bottom',
          end: '400% bottom',
        },
      })
      .to(wrapper2Ref.current, { className: `+=${cx('wrapper')} on` }, 0.5)
      .to(wrapper3Ref.current, { className: `+=${cx('wrapper')} on` }, 1.0);
  }, [isMobile]);

  return (
    <div ref={containerRef} className={cx('container')}>
      {/* Slide #1 */}
      <div ref={wrapper1Ref} className={cx(['wrapper', 'on'])}>
        <div className={cx(['left', 'bg01'])}>
          <img src={require('../../assets/images/home/s02/symbol01.png')} alt="우수한 품질" />
        </div>
        <div className={cx(['right', 'bg01'])}>
          <img src={require('../../assets/images/home/s02/icon01.png')} alt="우수한 품질" />
          <h2>우수한 품질</h2>
          <p>
            5개의 필터를 거친 정수 사용 및
            <br />
            매일 수질검사 실시로 품질의 차별화
          </p>
        </div>
      </div>
      {/* Slide #2 */}
      <div ref={wrapper2Ref} className={cx('wrapper')}>
        <div className={cx(['left', 'bg02'])}>
          <img src={require('../../assets/images/home/s02/symbol02.png')} alt="Oh My Green" />
        </div>
        <div className={cx(['right', 'bg02'])}>
          <img src={require('../../assets/images/home/s02/icon02.png')} alt="Oh My Green" />
          <h2>Oh My Green</h2>
          <p>
            탄소 배출량을 줄이고
            <br />
            환경을 생각하는 지속적인 노력
          </p>
        </div>
      </div>
      {/* Slide #3 */}
      <div ref={wrapper3Ref} className={cx('wrapper')}>
        <div className={cx(['left', 'bg03'])}>
          <img src={require('../../assets/images/home/s02/symbol03.png')} alt="AWS 인증" />
        </div>
        <div className={cx(['right', 'bg03'])}>
          <img src={require('../../assets/images/home/s02/icon03.png')} alt="AWS 인증" />
          <h2>AWS 인증</h2>
          <p>
            수자원의 지속 가능성에 대한 관심과 노력을 통한
            <br />
            국내 먹는샘물 업계 최초 AWS 인증 획득
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section;

import classNames from 'classnames/bind';

import styles from './S01.module.scss';

const cx = classNames.bind(styles);

const S01 = () => {
  return (
    <>
      <div className={cx('container')}>
        <div className={cx('limiter')}>
          <h2 className={cx('title')}>부드러운 맛과 톡 쏘는 탄산의 완벽한 조화</h2>
          <p className={cx('text')}>천연 향료만을 사용해 산뜻하고 깔끔한 맛과 강력한 탄산이 만나 어디에나 잘 어울립니다!</p>
        </div>
        <img
          src={require('../../../assets/images/products/breezy/s01/product.png')}
          alt="풀무원 스파클링 브리지톡"
          className={cx(['product', 'desktop'])}
        />
        <img
          src={require('../../../assets/images/products/breezy/s01/product-mobile.png')}
          alt="풀무원 스파클링 브리지톡"
          className={cx(['product', 'mobile'])}
        />
      </div>
    </>
  );
};

export default S01;

import PageTitle from '../../../components/shared/PageTitle';
import S01 from './S01';
import S02 from './S02';
import S03 from './S03';
import S04 from './S04';
import S05 from './S05';
import Outro from '../../../components/product/Outro';

const Deepsea = () => {
  return (
    <div>
      <PageTitle title="풀무원 해양심층수" subtitle="깊은 바다 속 청정 미네랄을 담은" />
      <S01 />
      <S02 />
      <S03 />
      <S04 />
      <S05 />
      <Outro
        title={'풀무원 해양심층수로\n건강한 수분 섭취를 시작해 보세요!'}
        shopUrl="https://brand.naver.com/pulmuonebynature/products/6881797613"
        color="blue"
      />
    </div>
  );
};

export default Deepsea;

import { Helmet } from 'react-helmet';

import Jumbotron from '../../components/shared/Jumbotron';
import TabBar from '../../components/shared/TabBar';
import TabGranite from './T01_granite';
import TabOhMyGreen from './T02_oh-my-green';
import TabWater from './T03_water';
import TabQuality from './T04_quality';
import jumbotron from '../../assets/images/brand/jumbotron.jpg';

const tabItems = [
  {
    title: '대보 화강암',
    link: '/브랜드-스토리/대보-화강암',
  },
  {
    title: 'Oh My Green',
    link: '/브랜드-스토리/Oh-My-Green',
  },
  {
    title: '수자원 보호',
    link: '/브랜드-스토리/수자원-보호',
  },
  {
    title: '품질관리',
    link: '/브랜드-스토리/품질관리',
  },
];

const Brand = () => {
  const tabIndex = tabItems.findIndex((navItem) => navItem.link === decodeURI(window.location.pathname));

  return (
    <div>
      <Helmet>
        <title>풀무원샘물 - 브랜드 스토리</title>
        <link
          rel="canonical"
          href="https://www.pulmuonewater.com/%EB%B8%8C%EB%9E%9C%EB%93%9C-%EC%8A%A4%ED%86%A0%EB%A6%AC/%EB%8C%80%EB%B3%B4-%ED%99%94%EA%B0%95%EC%95%94"
        />
      </Helmet>
      <Jumbotron title="브랜드 스토리" subtitle="Brand Story" background={jumbotron} />
      <TabBar items={tabItems} activeIndex={tabIndex} />
      {tabIndex === 0 && <TabGranite />}
      {tabIndex === 1 && <TabOhMyGreen />}
      {tabIndex === 2 && <TabWater />}
      {tabIndex === 3 && <TabQuality />}
    </div>
  );
};

export default Brand;

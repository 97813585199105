import classNames from 'classnames/bind';

import styles from './S02.module.scss';

const cx = classNames.bind(styles);

const S02 = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('limiter')}>
        <video controls>
          <source src={require('../../../assets/video/pulmuonewater-deepsea.mp4')} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default S02;

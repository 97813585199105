import classNames from 'classnames/bind';

import styles from './S04.module.scss';
import product01 from '../../../assets/images/products/breezy/s04/product01.png';
import product02 from '../../../assets/images/products/breezy/s04/product02.png';
import product03 from '../../../assets/images/products/breezy/s04/product03.png';
import product04 from '../../../assets/images/products/breezy/s04/product04.png';
import product05 from '../../../assets/images/products/breezy/s04/product05.png';
import product06 from '../../../assets/images/products/breezy/s04/product06.png';
import product07 from '../../../assets/images/products/breezy/s04/product07.png';
import Nutrient from '../../../components/product/Nutrient';
import Products from '../../../components/product/Products';

const cx = classNames.bind(styles);

const S04 = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('limiter')}>
        <Products
          products={[
            { sub: '깔끔한 맛과 강한 탄산, 극강의 청량감!', title: '브리지톡 플레인 500mL', image: product01, text: '500mL × 20개입' },
            { sub: '신맛, 단맛 등이 조화로운 자몽 특유의 맛', title: '브리지톡 자몽 500mL', image: product02, text: '500mL × 20개입' },
            { sub: '상큼한 라임 맛이 기분 좋은 리프레쉬!', title: '브리지톡 라임 500mL', image: product03, text: '500mL × 20개입' },
            { sub: '레몬의 새콤달콤함을 산뜻하게', title: '브리지톡 레몬 500mL', image: product04, text: '500mL × 20개입' },
            { sub: '달콤한 샤인머스캣 맛에 강탄산', title: '브리지톡 샤인머스캣 500mL', image: product05, text: '500mL × 20개입' },
            { sub: '브리지톡 플레인의 무라벨 제품', title: '브리지톡 플레인 eco 500mL', image: product06, text: '500mL × 20개입' },
            { sub: '강탄산수를 190mL용량으로 간편하게!', title: '브리지톡 플레인 190mL', image: product07, text: '190mL X 30개입' },
          ]}
          color="#038383"
          width="33%"
        />
        <Nutrient
          data={{
            head: ['열량', '나트륨', '탄수화물', '당류', '지방', '트랜스지방', '포화지방', '콜레스테롤', '단백질'],
            body: [['0kcal', '0mg', '0g', '0g', '0g', '0g', '0g', '0mg', '0mg']],
          }}
          color="#d8f2eb"
        />
        <div className={cx('guide')}>
          1일 영양성분 기준치에 대한 비율(%)은 2,000 kcal 기준이므로 개인의 필요 열량에 따라 다를 수 있습니다.
        </div>
      </div>
    </div>
  );
};

export default S04;

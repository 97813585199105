import React from 'react';
import classNames from 'classnames/bind';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Report2023 = () => {
  return (
    <div className={cx('limiter')}>
      <div className={cx('table')}>
        <p className={cx('sub')}>전자공시 - 21기 결산 공고</p>
        <h3 className={cx('title')}>재무상태표</h3>
        <p className={cx('date')}>2023년 12월 31일 (단위: 원)</p>
        <div className={cx('scrollable')}>
          <div className={cx('head')}>
            <ul>
              <li style={{ flex: 1 }}>과목</li>
              <li style={{ flex: 1 }}>제 21(당) 기말</li>
              <li style={{ flex: 1 }}>제 20(전) 기말</li>
            </ul>
          </div>
          <div className={cx('body')}>
            <ul>
              <li>
                <ul>
                  <li className={cx('point')}>자산</li>
                  <li></li>
                  <li></li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>유동자산</li>
                  <li>63,707,804,240</li>
                  <li>13,054,149,320</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>현금및현금성자산</li>
                  <li>9,521,938,289</li>
                  <li>128,742,539</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>매출채권및기타채권</li>
                  <li>6,836,407,410</li>
                  <li>6,821,077,932</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>재고자산</li>
                  <li>7,050,639,955</li>
                  <li>5,989,718,833</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타유동자산</li>
                  <li>261,099,076</li>
                  <li>114,610,016</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>단기투자자산</li>
                  <li>40,037,719,510</li>
                  <li>-</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>비유동자산</li>
                  <li>53,282,562,871</li>
                  <li>48,243,109,507</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>장기매출채권및기타채권</li>
                  <li>892,207,524</li>
                  <li>1,002,659,252</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>유형자산</li>
                  <li>42,983,849,834</li>
                  <li>38,141,004,255</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>무형자산</li>
                  <li>8,024,861,862</li>
                  <li>7,708,862,476</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>이연법인세자산</li>
                  <li>1,381,643,651</li>
                  <li>1,390,583,524</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>자산총계</li>
                  <li>116,990,367,111</li>
                  <li>61,297,258,827</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className={cx('point')}>부채및자본</li>
                  <li></li>
                  <li></li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>유동부채</li>
                  <li>19,713,421,888</li>
                  <li>20,489,723,384</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>매입채무및기타채부</li>
                  <li>16,733,438,084</li>
                  <li>18,305,889,934</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>유동성리스부채</li>
                  <li>818,314,677</li>
                  <li>621,526,105</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>당기법인세부채</li>
                  <li>782,144,276</li>
                  <li>305,376,104</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타유동부채</li>
                  <li>1,097,231,665</li>
                  <li>1,245,181,241</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타유동충당부채</li>
                  <li>282,293,186</li>
                  <li>11,750,000</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>비유동부채</li>
                  <li>10,438,453,466</li>
                  <li>8,282,403,457</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>장기차입금</li>
                  <li>4,280,000,000</li>
                  <li>2,300,000,000</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>장기리스부채</li>
                  <li>343,599,142</li>
                  <li>525,534,208</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>순확정급여부채</li>
                  <li>5,652,496,078</li>
                  <li>5,316,131,738</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타비유동총당부채</li>
                  <li>162,358,246</li>
                  <li>140,737,511</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>부채총계</li>
                  <li>30,151,875,354</li>
                  <li>28,772,126,841</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>자본금</li>
                  <li>11,012,460,000</li>
                  <li>6,778,760,000</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타불입자본</li>
                  <li>52,407,766,754</li>
                  <li>6,751,882,994</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>이익잉여금</li>
                  <li>23,418,265,003</li>
                  <li>18,994,488,992</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>자본총계</li>
                  <li>86,838,491,757</li>
                  <li>32,525,131,986</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>부채및자본총계</li>
                  <li>116,990,367,111</li>
                  <li>61,297,258,827</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={cx('inner')}>
        <div className={cx('ann')}>
          <p>위와 같이 공고함</p>
          <ul>
            <li>2024년 3월 29일</li>
            <li>풀무원샘물 주식회사</li>
            <li>대표이사 정희련</li>
          </ul>
        </div>
        <div className={cx('text')}>
          <p>
            감사의견 : 우리의 의견으로는 회사의 재무제표는 2023년 12월 31일 현재의 재무상태, 동일로 종료되는 보고기간의 재무성과 및
            현금흐름을 한국채택국제회계기준에 따라 중요성의 관점에서 공정하게 표시하고 있습니다.
            <span>삼일회계법인 대표이사 윤훈수</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Report2023;

import classNames from 'classnames/bind';

import styles from './S01.module.scss';

import background from '../../../assets/images/products/tuo/s01/background.jpg';

const cx = classNames.bind(styles);

const S01 = () => {
  return (
    <div className={cx('container')} style={{ backgroundImage: `url(${background})` }}>
      <div className={cx('limiter')}>
        <h2 className={cx('title')}>나의 첫 워터팩 라이프</h2>
        <p className={cx('text')}>
          투오는 스마트한 내추럴 미네랄 워터 음용을 가능하게 합니다.
          <br />
          쉽게 찢어지지 않는 PE, PA 레이어링 재질 적용,
          <br />
          파우치 형태의 천연광천수를 담은 미네랄 워터팩으로
          <br />
          플라스틱 사용량을 줄이고 폐기물 부피를 혁신적으로 줄입니다.
        </p>
      </div>
    </div>
  );
};

export default S01;

import classNames from 'classnames/bind';

import styles from './S01.module.scss';

const cx = classNames.bind(styles);

const S01 = () => {
  return (
    <>
      <div className={cx('container')}>
        <h2 className={cx('title')}>천연 향료와 레몬 농축액을 넣어 깔끔한 맛</h2>
        <p className={cx('desc')}>부드러운 탄산과 천연 재료로 추가 믹스없이 간편하게 칵테일이나 에이드를 즐길 수 있습니다</p>
        <img
          src={require('../../../assets/images/products/tonic/s01/product.png')}
          alt="풀무원 브리지톡 토닉워터"
          className={cx('product')}
        />
      </div>
    </>
  );
};

export default S01;

import PageTitle from '../../../components/shared/PageTitle';
import S01 from './S01';
import S03 from './S03';
import S04 from './S04';
import S05 from './S05';
import Outro from '../../../components/product/Outro';

const Breezy = () => {
  return (
    <div>
      <PageTitle title="풀무원 스파클링 브리지톡" subtitle="미네랄이 풍부한 강탄산수" />
      <S01 />
      <S03 />
      <S04 />
      <S05 />
      <Outro
        title={'풀무원 스파클링 브리지톡으로\n건강한 수분 섭취를 시작해 보세요!'}
        shopUrl="https://brand.naver.com/pulmuonebynature/category/92ddb586586f4a7cba50f6bd7cc6c91f?cp=2"
        color="emerald"
      />
    </div>
  );
};

export default Breezy;

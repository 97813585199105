import classNames from 'classnames/bind';

import styles from './S05.module.scss';

const cx = classNames.bind(styles);

const S05 = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('limiter')}>
        <h2 className={cx('headline')}>스파클링 브리지톡 이렇게 즐겨보세요!</h2>
        <p className={cx('desc')}>브리지톡으로 특별해지는 하루</p>
        <ul className={cx('features')}>
          <li className={cx('feature')}>
            <div className={cx('imageWrapper')}>
              <img src={require('../../../assets/images/products/breezy/s05/feature1.png')} alt="홈메이드 카페음료" />
              <h3 className={cx('title')}>홈메이드 카페음료</h3>
            </div>
            <div className={cx('bottom')}>브리지톡으로 맛있는 에이드를 손쉽게 만들어 즐겨보세요!</div>
          </li>
          <li className={cx('feature')}>
            <div className={cx('imageWrapper')}>
              <img src={require('../../../assets/images/products/breezy/s05/feature2.png')} alt="좋아하는 음식과 함께" />
              <h3 className={cx('title')}>좋아하는 음식과 함께</h3>
            </div>
            <div className={cx('bottom')}>내가 좋아하는 음식과 함께 즐기면 상쾌함이 가득!</div>
          </li>
          <li className={cx('feature')}>
            <div className={cx('imageWrapper')}>
              <img src={require('../../../assets/images/products/breezy/s05/feature3.png')} alt="일상 속 언제 어디서든" />
              <h3 className={cx('title')}>일상 속 언제 어디서든</h3>
            </div>
            <div className={cx('bottom')}>여행, 운동, 일할 때 등 톡 쏘는 순간이 필요한 일상 속에서 즐겨보세요!</div>
          </li>
        </ul>
        <p className={cx(['desc', 'margin'])}>스파클링 브리지톡으로 즐기는 간단 홈카페 레시피</p>
        <div className={cx('box')}>
          <img src={require('../../../assets/images/products/breezy/s05/bottle.png')} alt="과일 에이드" />
          <div className={cx('wrapper')}>
            <h3 className={cx('sub')}>달콤하고 톡 쏘는 맛!</h3>
            <h2 className={cx('title')}>과일 에이드</h2>
            <ul>
              <li>
                <div className={cx('number')}>1</div>
                <div className={cx('content')}>컵에 원하는 과일청을 1/4정도 담는다.</div>
              </li>
              <li>
                <div className={cx('number')}>2</div>
                <div className={cx('content')}>브리지톡을 조금씩 넣으면서 과일청과 함께 잘 저어준다.</div>
              </li>
              <li>
                <div className={cx('number')}>3</div>
                <div className={cx('content')}>취향에 따라 설탕이나 시럽을 넣어 즐겨준다.</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default S05;

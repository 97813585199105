import React from 'react';
import classNames from 'classnames/bind';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Report2022 = () => {
  return (
    <div className={cx('limiter')}>
      <div className={cx('table')}>
        <p className={cx('sub')}>전자공시 - 20기 결산 공고</p>
        <h3 className={cx('title')}>재무상태표</h3>
        <p className={cx('date')}>2022년 12월 31일 (단위: 원)</p>
        <div className={cx('scrollable')}>
          <div className={cx('head')}>
            <ul>
              <li style={{ flex: 1 }}>과목</li>
              <li style={{ flex: 1 }}>제 20(당) 기말</li>
              <li style={{ flex: 1 }}>제 19(전) 기말</li>
            </ul>
          </div>
          <div className={cx('body')}>
            <ul>
              <li>
                <ul>
                  <li className={cx('point')}>자산</li>
                  <li></li>
                  <li></li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>유동자산</li>
                  <li>13,054,149,320</li>
                  <li>11,719,520,405</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>현금및현금성자산</li>
                  <li>128,742,539</li>
                  <li>6,000,966</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>매출채권및기타채권</li>
                  <li>6,821,077,932</li>
                  <li>5,767,622,199</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>재고자산</li>
                  <li>5,989,718,833</li>
                  <li>5,666,337,622</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타유동자산</li>
                  <li>114,610,016</li>
                  <li>137,415,729</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>당기법인세자산</li>
                  <li>-</li>
                  <li>142,143,889</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>비유동자산</li>
                  <li>48,243,109,507</li>
                  <li>42,848,192,695</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>장기매출채권및기타채권</li>
                  <li>1,002,659,252</li>
                  <li>1,157,564,184</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>유형자산</li>
                  <li>38,141,004,255</li>
                  <li>32,231,325,536</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>무형자산</li>
                  <li>7,708,862,476</li>
                  <li>7,662,482,150</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>이연법인세자산</li>
                  <li>1,390,583,524</li>
                  <li>1,796,820,825</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>자산총계</li>
                  <li>61,297,258,827</li>
                  <li>54,567,713,100</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className={cx('point')}>부채및자본</li>
                  <li></li>
                  <li></li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>유동부채</li>
                  <li>20,489,723,384</li>
                  <li>16,640,425,993</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>매입채무및기타채부</li>
                  <li>18,305,889,934</li>
                  <li>10,656,262,756</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>단기차입금</li>
                  <li>-</li>
                  <li>4,156,705,678</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>유동성리스부채</li>
                  <li>621,526,105</li>
                  <li>408,756,664</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>당기법인세부채</li>
                  <li>305,376,104</li>
                  <li>-</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타유동부채</li>
                  <li>1,245,181,241</li>
                  <li>1,418,700,895</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타유동충당부채</li>
                  <li>11,750,000</li>
                  <li>-</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>비유동부채</li>
                  <li>8,282,403,457</li>
                  <li>8,001,695,261</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>장기차입금</li>
                  <li>2,300,000,000</li>
                  <li>-</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>장기리스부채</li>
                  <li>525,534,208</li>
                  <li>874,456,108</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>순확정급여부채</li>
                  <li>5,316,131,738</li>
                  <li>6,957,585,949</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타비유동총당부채</li>
                  <li>140,737,511</li>
                  <li>169,653,204</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>부채총계</li>
                  <li>28,772,126,841</li>
                  <li>24,642,121,254</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>자본금</li>
                  <li>6,778,760,000</li>
                  <li>6,778,760,000</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타불입자본</li>
                  <li>6,751,882,994</li>
                  <li>6,751,882,994</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>이익잉여금</li>
                  <li>18,994,488,992</li>
                  <li>16,394,948,852</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>자본총계</li>
                  <li>32,525,131,986</li>
                  <li>29,925,591,846</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>부채및자본총계</li>
                  <li>61,297,258,827</li>
                  <li>54,567,713,100</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={cx('inner')}>
        <div className={cx('ann')}>
          <p>위와 같이 공고함</p>
          <ul>
            <li>2023년 3월 31일</li>
            <li>풀무원샘물 주식회사</li>
            <li>대표이사 정희련</li>
          </ul>
        </div>
        <div className={cx('text')}>
          <p>
            감사의견 : 우리의 의견으로는 회사의 재무제표는 2022년 12월 31일 현재의 재무상태, 동일로 종료되는 보고기간의 재무성과 및
            현금흐름을 한국채택국제회계기준에 따라 중요성의 관점에서 공정하게 표시하고 있습니다.
            <span>삼일회계법인 대표이사 윤훈수</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Report2022;

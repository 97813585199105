import React from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Helmet } from 'react-helmet';

import S01 from './S01';
import S02 from './S02';
import S03 from './S03';
import S04 from './S04';
import Popup from './Popup';

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>풀무원샘물</title>
        <link rel="canonical" href="https://www.pulmuonewater.com/" />
      </Helmet>
      <S01 />
      <S02 />
      <S03 />
      <S04 />
      <Popup />
    </div>
  );
};

export default Home;

import classNames from 'classnames/bind';

import styles from './S03.module.scss';

const cx = classNames.bind(styles);

const S03 = () => {
  return (
    <div className={cx('container')}>
      <h2 className={cx('headline')}>
        <span className={cx('accent')}>해양심층수</span>는?
      </h2>
      <p className={cx('desc')}>해양심층수는 북극의 빙하가 녹아 미네랄띠를 형성해 깊은 수심에서 수천년을 순환한 물입니다.</p>
      <img src={require('../../../assets/images/products/deepsea/s03/figure1.png')} alt="해양심층수" className={cx('figure1')} />
      <p className={cx('desc')}>
        태양광이 거의 도달하지 않는 깊은 바닷물로 미생물이나 박테리아(병원균) 등이 거의 없고 안정된 저온을 유지하며 해양식물의 성장에
        필수적인 영양염류가 풍부하고 미네랄 밸런스가 뛰어난 특징이 있습니다.
      </p>
      <h2 className={cx(['headline', 'feature'])}>
        해양심층수는 <span className={cx('accent')}>5가지 특성</span>으로 인해
        <br />
        지구상 가장 깨끗한 해수로 분류됩니다.
      </h2>
      <img src={require('../../../assets/images/products/deepsea/s03/figure2.png')} alt="5가지 특성" className={cx('figure2')} />
    </div>
  );
};

export default S03;

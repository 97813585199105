import classNames from 'classnames/bind';

import styles from './Nutrient.module.scss';

const cx = classNames.bind(styles);

const Nutrient = ({ data, color, leading = false }) => {
  return (
    <div className={cx('container')}>
      <h3 className={cx('title')}>영양성분</h3>
      <div className={cx('table')}>
        <ul className={cx('head')}>
          {leading && <li className={cx(['column', 'leading'])} style={{ backgroundColor: color }} />}
          {data.head.map((name, index) => (
            <li key={index} className={cx('column')} style={{ backgroundColor: color }}>
              {name}
            </li>
          ))}
        </ul>
        <ul className={cx('body')}>
          {data.body.map((row, index) => (
            <li key={index} className={cx('row')}>
              <ul>
                {row.map((value, index) => (
                  <li
                    key={index}
                    className={cx(['column', { leading: leading && index === 0 }])}
                    style={leading && index === 0 ? { backgroundColor: color } : null}>
                    {value}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Nutrient;

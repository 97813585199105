import classNames from 'classnames/bind';

import styles from './S03.module.scss';
import Nutrient from '../../../components/product/Nutrient';
import Products from '../../../components/product/Products';

const cx = classNames.bind(styles);

const S03 = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('limiter')}>
        <Products
          products={[
            {
              sub: '상큼한 레몬을 더해 깔끔한 맛!',
              title: '토닉워터 오리지널 400mL',
              image: require('../../../assets/images/products/tonic/s04/product.png'),
              text: '400mL × 20개입',
            },
          ]}
          color="#4A8B12"
          width="33%"
        />
        <Nutrient
          data={{
            head: ['열량', '나트륨', '탄수화물', '당류', '지방', '트랜스지방', '포화지방', '콜레스테롤', '단백질'],
            body: [['140kcal', '5mg', '35g', '30g', '0g', '0g', '0g', '0mg', '0mg']],
          }}
          color="#E1ED90"
        />
        <div className={cx('guide')}>
          1일 영양성분 기준치에 대한 비율(%)은 2,000 kcal 기준이므로 개인의 필요 열량에 따라 다를 수 있습니다.
        </div>
      </div>
    </div>
  );
};

export default S03;

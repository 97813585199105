import classNames from 'classnames/bind';

import styles from './S01.module.scss';

import background from '../../../assets/images/products/deepsea/s01/background.jpg';
import bubble_left from '../../../assets/images/products/deepsea/s01/bubble_left.png';
import bubble_right from '../../../assets/images/products/deepsea/s01/bubble_right.png';
import product from '../../../assets/images/products/deepsea/s01/product.png';

const cx = classNames.bind(styles);

const S01 = () => {
  return (
    <>
      <div className={cx('container')} style={{ backgroundImage: `url(${background})` }}>
        <div className={cx('limiter')}>
          <h2 className={cx('title')}>풀무원샘물이 엄선하여 만든 프리미엄 해양심층수</h2>
          <p className={cx('text')}>해양심층수만의 높은 미네랄 함량으로 수분 건강을 되찾아 보세요!</p>
          <div className={cx('objects')}>
            <img className={cx('left')} src={bubble_left} alt="" />
            <img className={cx('right')} src={bubble_right} alt="" />
          </div>
        </div>
        <img className={cx('product')} src={product} alt="풀무원 해양심층수" />
      </div>
    </>
  );
};

export default S01;

import classNames from 'classnames/bind';

import styles from './S04.module.scss';

const cx = classNames.bind(styles);

const S05 = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('section')}>
        <div className={cx('limiter')}>
          <h3>
            모두가 안심하고 마실 수 있도록
            <br />
            풀무원 퓨어는 더 꼼꼼하고 철저하게 관리합니다.
          </h3>
          <p>5개의 필터 정수를 거쳐 더 깨끗한 맛!</p>
          <img src={require('../../../assets/images/products/pure/s04/box.png')} alt="풀무원샘물 검사항목" />
        </div>
      </div>
    </div>
  );
};

export default S05;

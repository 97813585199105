import classNames from 'classnames/bind';

import styles from './S02.module.scss';
import product from '../../../assets/images/products/tuo/s02/product.jpg';
import Nutrient from '../../../components/product/Nutrient';
import Products from '../../../components/product/Products';
import Download from '../../../components/product/Download';

const cx = classNames.bind(styles);

const S02 = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('limiter')}>
        <Products products={[{ title: '투오 미니 워터팩 4.2L', image: product }]} color="#64aadd" />
        <Nutrient
          data={{
            head: ['칼슘(Ca)', '나트륨(Na)', '마그네슘(Mg)', '칼륨(K)', '불소(F)'],
            body: [['10.2 ~ 15.5', '6.0 ~ 9.2', '1.5 ~ 2.4', '0.4 ~ 0.8', '0.0 ~ 0.3']],
          }}
          color="#64aadd"
        />
        <Download
          title="미니 워터팩은 안심하고 드실 수 있습니다!"
          names={['수질 검사 성적서']}
          files={['/static/report/Water_Report-Mini_Waterpack.pdf']}
          color="#64aadd"
        />
      </div>
    </div>
  );
};

export default S02;

import classNames from 'classnames/bind';

import styles from './S01.module.scss';

import background from '../../../assets/images/products/pure/s01/background.jpg';
import bubble_left from '../../../assets/images/products/pure/s01/bubble_left.png';
import bubble_right from '../../../assets/images/products/pure/s01/bubble_right.png';
import leaf_left from '../../../assets/images/products/pure/s01/leaf_left.png';
import leaf_right from '../../../assets/images/products/pure/s01/leaf_right.png';
import product from '../../../assets/images/products/pure/s01/product.png';

const cx = classNames.bind(styles);

const S01 = () => {
  return (
    <div className={cx('container')} style={{ backgroundImage: `url(${background})` }}>
      <div className={cx('limiter')}>
        <h2 className={cx('title')}>5개의 필터 정수를 거쳐 더 깨끗한 맛!</h2>
        <p className={cx('text')}>화강암반수의 깨끗함만을 담아 부드러운 맛을 느껴보세요!</p>
      </div>
      <div className={cx('objects')}>
        <img className={cx(['leaf', 'left'])} src={leaf_left} alt="" />
        <img className={cx(['leaf', 'right'])} src={leaf_right} alt="" />
        <img className={cx(['bubble', 'left'])} src={bubble_left} alt="" />
        <img className={cx(['bubble', 'right'])} src={bubble_right} alt="" />
      </div>
      <img className={cx('product')} src={product} alt="풀무원 퓨어" />
    </div>
  );
};

export default S01;

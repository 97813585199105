import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';

import styles from './S01.module.scss';

const cx = classNames.bind(styles);

const SLIDES = [
  {
    title: '건강한 물을 마시는 새로운 방법',
    content: '투오 미니 워터팩 & 디스펜서',
    uri: '/제품안내/풀무원투오-미니-워터팩',
    showOnlyMobile: true,
  },
  {
    title: '풀무원샘물',
    content: '바르게 채우다',
    uri: '/브랜드-스토리/대보-화강암',
    showOnlyMobile: false,
  },
  {
    title: '풀무원샘물',
    content: '청정 화강암반수의 청량한 맛',
    uri: '/제품안내/풀무원샘물',
    showOnlyMobile: false,
  },
  {
    title: '풀무원 스파클링 브리지톡',
    content: '미네랄이 풍부한 강탄산수',
    uri: '/제품안내/풀무원-스파클링-브리지톡',
    showOnlyMobile: false,
  },
  {
    title: '풀무원 스파클링 브리지톡',
    content: '강탄산수를 190mL용량으로 간편하게!',
    uri: '/제품안내/풀무원-스파클링-브리지톡',
    showOnlyMobile: false,
  },
  {
    title: '풀무원 워터루틴',
    content: '일상에 늘 함께 하는',
    uri: '/제품안내/풀무원-워터루틴',
    showOnlyMobile: false,
  },
  {
    title: '풀무원 해양심층수',
    content: '바다의 청정 미네랄을 담은 프리미엄 생수',
    uri: '/제품안내/풀무원-해양심층수',
    showOnlyMobile: false,
  },
  {
    title: '풀무원 퓨어',
    content: '믿을 수 있는 순수 내추럴 미네랄 워터',
    uri: '/제품안내/풀무원-퓨어',
    showOnlyMobile: false,
  },
  {
    title: '풀무원 스파클링 브리지톡 토닉워터 오리지널',
    content: '상큼한 레몬을 더해 더 깔끔한 맛!',
    uri: '/제품안내/풀무원-브리지톡-토닉워터',
    showOnlyMobile: false,
  },
];

const Section = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((currentIndex) => (currentIndex + 1) % SLIDES.length);
    }, 6000);
    return () => clearInterval(interval);
  }, []);

  const moveToDetail = () => {
    navigate(SLIDES[currentIndex].uri);
  };

  const moveToPrevious = () => {
    setCurrentIndex((currentIndex + (SLIDES.length - 1)) % SLIDES.length);
  };

  const moveToNext = () => {
    setCurrentIndex((currentIndex + 1) % SLIDES.length);
  };

  return (
    <div className={cx('container')}>
      {SLIDES.map((slide, index) => (
        <div key={index} className={cx(['slide', { active: index === currentIndex }])}>
          <div className={cx(['background', `bg0${index + 1}`])} onClick={moveToDetail} />
          <div className={cx(['wrapper', { mobile: slide.showOnlyMobile }])} onClick={moveToDetail}>
            <h2>{slide.content}</h2>
            <h1>
              {slide.title.split('\n').map((line) => (
                <>
                  {line}
                  <br />
                </>
              ))}
            </h1>
          </div>
        </div>
      ))}
      <div className={cx(['arrow', 'left'])} onClick={moveToPrevious}>
        <IoChevronBackOutline size={40} color="white" />
      </div>
      <div className={cx(['arrow', 'right'])} onClick={moveToNext}>
        <IoChevronForwardOutline size={40} color="white" />
      </div>
      <div className={cx('pagination')}>
        {SLIDES.map((_, index) => (
          <div key={index} className={cx(['dot', { active: index === currentIndex }])} onClick={() => setCurrentIndex(index)} />
        ))}
      </div>
    </div>
  );
};

export default Section;

import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import axios from 'axios';

import styles from './S03.module.scss';

const cx = classNames.bind(styles);

const Section = () => {
  const [feeds, setFeeds] = useState();

  useEffect(() => {
    axios
      .get('https://pulmuone.console.flyground.co.kr/api/board/feeds', {})
      .then((response) => response.data)
      .then(({ success, data }) => {
        if (success) {
          setFeeds(data);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  const openWindow = (url) => {
    window.open(url);
  };

  return (
    <div className={cx('container')}>
      <h1>풀무원샘물 인스타그램</h1>
      <h2>@pulsaem</h2>
      <ul>
        {feeds?.map((feed, index) => (
          <li key={index} style={{ backgroundImage: `url('${feed.fileUrl}')` }} onClick={() => openWindow(feed.linkUrl)} />
        ))}
      </ul>
      <a href="https://www.instagram.com/pulsaem/" rel="noreferrer" target="_blank">
        인스타그램 더보기
      </a>
    </div>
  );
};

export default Section;

import React from 'react';
import classNames from 'classnames/bind';

import styles from './Products.module.scss';
import Specs from './Specs';

const cx = classNames.bind(styles);

const Products = ({ title = '제품소개', products, color, width = '25%' }) => {
  return (
    <div className={cx('container')}>
      <h2 className={cx('title')}>{title}</h2>
      <ul className={cx('products')}>
        {products.map(({ sub, title, image, price, text, specs }, index) => (
          <li key={index} style={{ width }}>
            <h3 className={cx('title')} style={{ color }}>
              {sub && <span className={cx('sub')}>{sub}</span>}
              {title}
            </h3>
            <div className={cx('image')} style={{ backgroundImage: `url(${image})` }}></div>
            {price && (
              <p className={cx('price')} style={{ backgroundColor: color }}>
                {price}
                <span className={cx('subprice')}>&nbsp;&nbsp;(부가세 별도)</span>
              </p>
            )}
            {text && (
              <p className={cx('info')}>
                {text.split('\n').map((line, index) => (
                  <span key={index}>{line}</span>
                ))}
              </p>
            )}
            {specs && <Specs data={specs} />}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Products;

export const FAQ = [
  {
    category: '먹는샘물',
    title: '풀무원샘물은 제품마다 유통기한이 왜 다른가요?',
    answer:
      '유통기한은 해당 기간 동안 실제로 모니터링을 하고 6개월 단위로 수질 분석 실시 및 최종적으로 환경부로부터 제품에 이상이 없음을 승인받아 판매 제품에 적용됩니다.\n\n<유통기한 안내>\n수원지: 포천시 / 제조사:풀무원샘물㈜- 제조일로부터 18개월 (2021년 7월 생산 제품부터 적용)',
  },
  {
    category: '먹는샘물',
    title: '대용량 먹는샘물 제품 및 냉온수기 관리 요령은 무엇인가요?',
    answer:
      '1. 제품은 직사광선이 없는 서늘한 곳에서 보관하세요. (온도가 올라가 미생물이 증식할 수 있음)\n2. 제품 장착하실 때, 반드시 입구의 비닐 커버를 벗기고 깨끗한 티슈로 2~3회 닦아 주세요.\n3. 냉온수기 냉, 온수 꼭지 아래 물받이는 항상 청결하게 유지해 주십시오. (고인 물은 미생물 증식, 악취의 원인)\n4. 제품을 냉온수기에 장착할 때는 병목을 손으로 잡지 마시고, 손잡이나 몸통을 이용하세요.\n5. 냉온수기 세척은 배송 가맹점 또는 전문 세척 업체에 문의하세요.',
  },
  {
    category: '먹는샘물',
    title: '냉온수기 설치 방법은 어떻게 되나요?',
    answer:
      '1. 바닥이 단단하고 수평 한 곳에 설치합니다.\n2. 공기가 잘 통할 수 있도록 벽면에서 약 10cm 가량 간격을 둡니다.\n3. 전원을 연결하기 전에 먼저 샘물을 올려놓고 2~3분가량 기다린 후 냉수 및 온수를 한 컵씩 뽑아낸 후 전원을 연결합니다.',
  },
  {
    category: '먹는샘물',
    title: '개봉하지 않은 제품도 녹조류가 발생할 수 있나요?',
    answer:
      '유통 과정 중 광선 등 외부의 적정조건이 이루어졌을 때는 미개봉상태에서도 녹조류가 발생될 수 있는데, 염소처리 등 일체의 화학처리를 하지 않은 상태에서 햇빛이 드는 곳에 또는 간접적으로 노출된 곳에 보관 되었다면 녹조류가 발생될 수 있습니다. 먹는샘물을 보관하실 때는 햇빛 등 직사광선을 피하고 사용 후 병 입구를 항상 막아주는 지혜가 필요합니다.',
  },
  {
    category: '먹는샘물',
    title: '물을 끓이거나 얼렸을 때 흰색 침전물이 생겨요.',
    answer:
      '물은 영하 5도씨부터 급속히 결빙하기 시작하는데, 물의 특성은 외부로부터 나이테와 같이 점차 속으로 얼어 들어가는데 특징이 있습니다. 그러나 물의 속성상 미네랄이나 이물질 등이 많을 경우 결빙 속도가 느리게 되며, 결국 결빙을 가로막던 이러한 물질들은 얼지 않는 곳의 점차 떠밀리게 되어 마지막에는 각종 미네랄들이 함축되어 있게 됩니다. 이것이 흰색 침전물로 우리의 육안으로 확인되는 것입니다. 이는 천연 미네랄 성분으로 안심하고 드셔도 됩니다.',
  },
  {
    category: '먹는샘물',
    title: '먹는샘물의 유통기한은 얼마인가요?',
    answer:
      '먹는샘물의 유통기한은 6개월로서 법으로 규정하고 있으나, 제품의 품질변화가 없다는 것을 과학적으로 입증하여 환경부장관의 승인을 받을 경우에는 유통기한을 연장할 수 있으며, 참고적으로 당사의 PET 제품은 환경부의 승인(조건부 연장 허가 획득)을 받아 유통기한이 1년으로 표시하고 있습니다. 18.9L의 경우에는 제조 후 소비되는 기간이 짧은 관계인 점을 감안하여 유통기한 연장을 신청하지 않은 것이며, 품질의 상태는 PET 제품과 동일합니다.',
  },
  {
    category: '먹는샘물',
    title: '먹는샘물 보관 방법이 어떻게 되나요?',
    answer:
      '천연 미네랄을 함유한 제품이기 때문에 햇볕이 드는 곳에 보관할 경우 녹조류가 발생할 수 있으니 직사광선이 없는 건조하고 서늘하며 냄새가 나지 않는 청결한 곳에 보관하십시오.',
  },
  {
    category: '먹는샘물',
    title: '먹는샘물은 안전한가요?',
    answer:
      '먹는샘물은 원수 자체가 다릅니다. 엄격한 환경영향평가 후 오염원이 없는 심층 지하수로 만들어지는 먹는샘물은 천연암반수로서, 인위적인 염소소독이나 인위적인 가열 같은 멸균소독을 하지 않습니다. (화학처리가 가능한 Bottled Water와 Natural Mineral Water는 분명 구분됨).\n\n따라서 소비 과정 중에 공기와 접촉하게 되면 시간 경과에 따라 일반 세균이 자연 증식할 수밖에 없습니다. 대표적인 먹는샘물 소비 국가인 일본이나 프랑스 등에서는 먹는샘물의 일반세균수를 원수에서 5CFU/㎖, 생산 직후 20CFU/㎖ 이하로 관리하고 있을 뿐 유통상태에서는 전혀 규제하지 않고 있습니다.',
  },
  {
    category: '먹는샘물',
    title: '보냉제(아이스팩) 대신 온 얼린 먹는샘물 제품을 녹여서 음용해도 괜찮은가요?',
    answer:
      '친환경 포장에 대한 사회적 관심이 높아지면서, 배송 시 화학 보냉제 대신 먹는샘물을 얼려 대체재로 사용하는 업체들이 늘어나고 있습니다. 이렇게 보냉제로 사용하기 위해 얼린 제품에서 종종 흰색 침전물이 발견되는 경우가 있는데, 이는 결빙 과정에서 함축되는 천연 미네랄이므로 안심하고 드셔도 됩니다. 보냉제로 사용되는 제품은 시중에서 판매되고 있는 제품과 동일한 제품이므로 식수로 사용 가능합니다. 단, 배송 중 제품 외부에 손상(찍힘, 깨짐 등)이 발생한 경우에는 제품의 오염 및 변질 우려가 있으므로 음용하지 않는 것을 권장합니다.',
  },
  {
    category: '냉온수기',
    title: 'A/S 기간은 어떻게 되며 어떻게 신청해야 하나요? 풀무원샘물로 연락하면 되나요?',
    answer:
      '기본 1년 동안 무상 A/S를 제공받으실 수 있으며, 당사 배송 가맹점이나 고객센터(1588-8655)로 연락하시면 서비스를 받으실 수 있습니다.',
  },
  {
    category: '냉온수기',
    title: '수돗물을 꽂아서 사용할 순 없는지요? 수돗물 정수 기능은 없나요?',
    answer: '본 제품은 먹는샘물 전용 냉온수기로써 수돗물을 꽂아서 사용하실 수 없습니다.',
  },
  {
    category: '주문/신청',
    title: '제주도에도 풀무원샘물이 배송 되나요?',
    answer:
      '제주도와 울릉도 및 일부 도서 산간 지역은 해당 지역 내 가맹점 유무에 따라 배송 가능 여부가 다를 수 있으므로 자세한 내용은 고객센터로 문의하여 주시기 바랍니다.',
  },
  {
    category: '주문/신청',
    title: '풀무원샘물 배송 가능 지역은 어디인가요?',
    answer:
      '풀무원샘물은 전 지역으로 배송이 가능합니다. 다만 제주도와 울릉도 및 일부 도서 산간 지역은 해당 지역 내 가맹점 유무에 따라 배송 가능 여부가 다를 수 있으므로 자세한 내용은 고객센터로 문의하여 주시기 바랍니다.',
  },
  {
    category: '주문/신청',
    title: '풀무원샘물 주문 방법이 어떻게 되나요?',
    answer:
      '1. 온라인 주문\n홈페이지의 문의하기를 통해 주문하시면 고객님 댁과 가장 가까운 가맹점에서 배송 서비스가 이루어집니다. 주문한 제품은 선 배송, 후 결재로 고객님께서 제품을 받으시고 대금 지불해주시면 됩니다. [고객센터] > [문의하기]를 이용해주시기 바랍니다.\n\n2. 전화 주문\n1588-8655로 전화하시면 친절하게 안내해 드립니다.\n[상담 시간]\n* 월요일 ~ 금요일 08:30 ~ 17:30\n(점심시간 12:00~13:00, 토/일/공휴일 휴무)\n* 문의사항이 있으시면 고객센터 1:1 문의에 남겨주시면 신속히 답변해 드리겠습니다.',
  },
  {
    category: '가맹점 모집',
    title: '풀무원샘물 가맹점 문의는 어떻게 하나요?',
    answer:
      '풀무원샘물 가맹점은 전국 어디서든 개설 가능하며, 선택하신 지역 영업담당자님께서 친절히 상담해 드릴 수 있으니 홈페이지를 통해 문의하세요. [고객센터] > [문의하기] > [가맹점 사업 신청] 문의 메뉴에서 가맹점 개설 문의를 하실 수 있습니다.',
  },
];

import classNames from 'classnames/bind';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';

import styles from './S05.module.scss';

const cx = classNames.bind(styles);

const PrevArrow = ({ onClick }) => {
  return (
    <div className={cx(['arrow', 'left'])} onClick={onClick}>
      <IoChevronBack size={32} color="white" />
    </div>
  );
};

const NextArrow = ({ onClick }) => {
  return (
    <div className={cx(['arrow', 'right'])} onClick={onClick}>
      <IoChevronForward size={32} color="white" />
    </div>
  );
};

const S05 = () => {
  return (
    <div className={cx('container')}>
      <h2 className={cx('headline')}>이제는 깨끗하고 몸에 좋은 물을 마셔야 할 때입니다.</h2>
      <Slider
        dots={true}
        infinite={true}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        prevArrow={<PrevArrow />}
        nextArrow={<NextArrow />}
        dotsClass={`slick-dots slick-thumb ${cx('dots')}`}
        className={cx('slider')}>
        <div className={cx('slide')}>
          <h2 className={cx('title')}>깊은 바다 속 깨끗한 물을 마셔요</h2>
          <img
            src={require('../../../assets/images/products/deepsea/s05/figure1-1.png')}
            alt="깊은 바다 속 깨끗한 물을 마셔요"
            className={cx('figure')}
          />
          <p className={cx('desc')}>
            해양심층수 (Premium Deep Sea Drinking Water)는 온도차에 의해 침강된 수심 200m 이상 깊은 곳의 바닷물로, 표층수와 섞이지 않고
            독자적인 해양심층수 벨트를 생성합니다. 그린랜드와 남극에서 발원하여 평균 2,000년의 시간동안 지구를 순환하면서 깨끗함을 유지하고
            있는 물입니다.
          </p>
        </div>
        <div className={cx('slide')}>
          <h2 className={cx('title')}>동해 바다의 깨끗한 물을 마셔요!</h2>
          <img
            src={require('../../../assets/images/products/deepsea/s05/figure1-2.png')}
            alt="동해 바다의 깨끗한 물을 마셔요!"
            className={cx('figure')}
          />
          <p className={cx('desc')}>
            그 중에서도 대한민국 동해는 미니 대양(Mini Ocean)의 특성을 띄고 있어 세계 학계에서 주목하는 해양심층수 취수 해역으로 인정 받고
            있습니다.
          </p>
        </div>
        <div className={cx('slide')}>
          <h2 className={cx('title')}>동해 바다의 깨끗한 물을 마셔요!</h2>
          <img
            src={require('../../../assets/images/products/deepsea/s05/figure1-3.png')}
            alt="동해 바다의 깨끗한 물을 마셔요!"
            className={cx('figure')}
          />
          <p className={cx('desc')}>
            청정한 속초해변의 동쪽 7km지점의 해저 510m에서 외부의 오염원이 없는 깨끗한 해양심층수 원수를 취수합니다.
          </p>
        </div>
      </Slider>

      <div className={cx('box')}>
        <h2 className={cx(['title', 'mineral'])}>내 몸에 가까운 미네랄 밸런스로 인체에 흡수가 빨라요!</h2>
        <img
          src={require('../../../assets/images/products/deepsea/s05/figure2.png')}
          alt="내 몸에 가까운 미네랄 밸런스로 인체에 흡수가 빨라요!"
          className={cx('figure')}
        />
        <p className={cx('desc')}>
          천연 미네랄의 함량이 먹는샘물보다 높은 풀무원 프리미엄 해양심층수는{' '}
          <span className={cx('accent')}>마그네슘, 칼슘, 칼륨의 비율이 3:1:1</span>로 임산부의 양수, 인체의 체액과 가장 유사한 밸런스를
          가지고 있어 인체에 흡수가 빠릅니다.
        </p>
      </div>
      <div className={cx('box')}>
        <h2 className={cx(['title', 'trust'])}>물이 만들어지는 그 과정까지 안전하여, 믿을 수 있어요!</h2>
        <img
          src={require('../../../assets/images/products/deepsea/s05/figure3.png')}
          alt="물이 만들어지는 그 과정까지 안전하여, 믿을 수 있어요!"
          className={cx('figure')}
        />
        <p className={cx('desc')}>먹는 해양심층수의 제조에 관해 해양수산부의 인가를 받아 제조하고 있는 공장에서 생산합니다.</p>
      </div>
    </div>
  );
};

export default S05;

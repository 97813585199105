import React from 'react';
import classNames from 'classnames/bind';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Report2021 = () => {
  return (
    <div className={cx('limiter')}>
      <div className={cx('table')}>
        <p className={cx('sub')}>전자공시 - 19기 결산 공고</p>
        <h3 className={cx('title')}>재무상태표</h3>
        <p className={cx('date')}>2021년 12월 31일 (단위: 원)</p>
        <div className={cx('scrollable')}>
          <div className={cx('head')}>
            <ul>
              <li style={{ flex: 1 }}>과목</li>
              <li style={{ flex: 1 }}>제 19(당)기</li>
              <li style={{ flex: 1 }}>
                제 18(전)기
                <br />
                (감사받지 않은 재무제표)
              </li>
              <li style={{ flex: 1 }}>
                제 18(전)기 기초
                <br />
                (감사받지 않은 재무제표)
              </li>
            </ul>
          </div>
          <div className={cx('body')}>
            <ul>
              <li>
                <ul>
                  <li className={cx('point')}>자산</li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>유동자산</li>
                  <li>11,719,520,405</li>
                  <li>11,253,689,075</li>
                  <li>10,339,555,334</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>현금및현금성자산</li>
                  <li>6,000,966</li>
                  <li>159,756,332</li>
                  <li>199,790,872</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>매출채권및기타채권</li>
                  <li>5,767,622,199</li>
                  <li>6,583,249,160</li>
                  <li>6,626,657,442</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>재고자산</li>
                  <li>5,666,337,622</li>
                  <li>4,333,493,012</li>
                  <li>3,344,080,076</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타유동자산</li>
                  <li>137,415,729</li>
                  <li>177,190,571</li>
                  <li>169,026,944</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>당기법인세자산</li>
                  <li>142,143,889</li>
                  <li></li>
                  <li></li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>비유동자산</li>
                  <li>42,848,192,695</li>
                  <li>43,148,941,013</li>
                  <li>43,291,042,299</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>장기매출채권및기타채권</li>
                  <li>1,157,564,184</li>
                  <li>1,378,743,574</li>
                  <li>1,156,399,612</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>유형자산</li>
                  <li>32,231,325,536</li>
                  <li>32,382,604,891</li>
                  <li>32,909,463,115</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>무형자산</li>
                  <li>7,662,482,150</li>
                  <li>7,577,552,859</li>
                  <li>7,629,149,259</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>이연법인세자산</li>
                  <li>1,796,820,825</li>
                  <li>1,810,039,689</li>
                  <li>1,596,030,313</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>자산총계</li>
                  <li>54,567,713,100</li>
                  <li>54,402,630,088</li>
                  <li>53,630,597,633</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li className={cx('point')}>부채및자본</li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>유동부채</li>
                  <li>16,640,425,993</li>
                  <li>21,240,967,198</li>
                  <li>30,948,944,955</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>매입채무및기타채부</li>
                  <li>10,656,262,756</li>
                  <li>12,627,059,781</li>
                  <li>12,365,475,702</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>단기차입금</li>
                  <li>4,156,705,678</li>
                  <li>5,021,521,813</li>
                  <li>15,037,681,110</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>유동성리스부채</li>
                  <li>408,756,664</li>
                  <li>369,381,384</li>
                  <li>369,381,384</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>당기법인세부채</li>
                  <li></li>
                  <li>1,537,561,739</li>
                  <li>1,605,026,969</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타유동부채</li>
                  <li>1,418,700,895</li>
                  <li>1,685,442,481</li>
                  <li>1,571,379,790</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>비유동부채</li>
                  <li>8,001,695,261</li>
                  <li>7,409,076,452</li>
                  <li>5,458,212,732</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>장기리스부채</li>
                  <li>874,456,108</li>
                  <li>1,435,644,655</li>
                  <li>546,305,522</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>순확정급여부채</li>
                  <li>6,957,585,949</li>
                  <li>5,925,466,695</li>
                  <li>4,861,722,878</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타비유동총당부채</li>
                  <li>169,653,204</li>
                  <li>47,965,102</li>
                  <li>50,184,332</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>부채총계</li>
                  <li>24,642,121,254</li>
                  <li>28,650,043,650</li>
                  <li>36,407,157,687</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>자본금</li>
                  <li>6,778,760,000</li>
                  <li>6,778,760,000</li>
                  <li>6,778,760,000</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>기타불입자본</li>
                  <li>6,751,882,994</li>
                  <li>6,751,882,994</li>
                  <li>6,751,882,994</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>이익잉여금</li>
                  <li>16,394,948,852</li>
                  <li>12,221,943,444</li>
                  <li>3,692,796,952</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>자본총계</li>
                  <li>29,925,591,846</li>
                  <li>25,752,586,438</li>
                  <li>17,223,439,946</li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>부채및자본총계</li>
                  <li>54,567,713,100</li>
                  <li>54,402,630,088</li>
                  <li>53,630,597,633</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={cx('inner')}>
        <div className={cx('ann')}>
          <p>위와 같이 공고함</p>
          <ul>
            <li>2022년 3월 31일</li>
            <li>풀무원샘물 주식회사</li>
            <li>대표이사 조현근</li>
          </ul>
        </div>
        <div className={cx('text')}>
          <p>
            감사의견 : 우리의 의견으로는 회사의 재무제표는 회사의 2021년 12월 31일 현재의 재무상태, 동일로 종료되는 보고기간의 재무성과 및
            현금흐름을 한국채택국제회계기준에 따라, 중요성의 관점에서 공정하게 표시하고 있습니다.
            <span>삼정회계법인 대표이사 김교태</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Report2021;

import classNames from 'classnames/bind';

import styles from './S02.module.scss';
import { IoChevronForward } from 'react-icons/io5';
import pulmuone_logo from '../../../assets/images/company/pulmuone/s02/pulmuone_logo.jpg';
import family_logo from '../../../assets/images/company/pulmuone/s02/family_logo.png';

const cx = classNames.bind(styles);

const S02 = () => {
  const moveToRecruit = () => {
    window.open('https://pulmuone.recruiter.co.kr/app/jobnotice/list');
  };

  return (
    <div className={cx('container')}>
      <div className={cx('limiter')}>
        <div className={cx('family')}>
          <img src={family_logo} alt="가족친화 우수기업" />
          <div className={cx('text')}>
            <h3 className={cx('title')}>
              가족친화 우수기업 <span>‘풀무원샘물‘</span>
            </h3>
            <p>근로자와 기업, 사회의 상생을 위한 가족친화제도를 모범적으로 운영하는 기업입니다.</p>
            <div className={cx('button')} onClick={moveToRecruit}>
              <div className={cx('buttonText')}>채용공고 보러가기</div>
              <IoChevronForward size={28} color="#e62b86" />
            </div>
          </div>
        </div>
        <div className={cx('pulmuone')}>
          <img src={pulmuone_logo} alt="풀무원" />
          <div className={cx('text')}>
            <h3 className={cx('title')}>
              바른 먹거리 정신 <span>‘풀무원’</span>
            </h3>
            <p>
              이웃사랑 정신, 즉 자연 그대로 신선하고 안전한 먹거리를 드리겠다는 이웃사랑의 정신으로서 이는 사람(풀무원)과 사람(고객) 과의
              아름답고 굳센 약속이며, 내 가족이 안심하고 먹을 수 있는 식품을 만든다는 풀무원의 의지입니다. 생명존중 정신, 즉 이웃사랑의 정신
              구현을 위해 생명의 근원인 자연을 사랑하고 살리는 것으로서, 작은 생명도 마음 놓고 살 수 있는 자연환경을 가꾸어 나가겠다는
              사람과 자연과의 약속입니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default S02;

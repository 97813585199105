import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from './Footer.module.scss';
import logo from '../../assets/images/shared/logo.jpg';
import facebook from '../../assets/images/shared/sns/facebook.png';
import instagram from '../../assets/images/shared/sns/instagram.png';
import blog from '../../assets/images/shared/sns/blog.png';

const cx = classNames.bind(styles);

const Footer = () => {
  const onChange = (event) => {
    const { value } = event.target;
    if (value) {
      if (navigator.userAgent.match(/iPhone|Android/i) !== null) {
        window.location.href = value;
      } else {
        window.open(value, new Date().getTime());
      }
    }
  };

  return (
    <>
      <footer className={cx('container')}>
        <div className={cx('limiter')}>
          <div className={cx('flex')}>
            <ul className={cx('customer')}>
              <li>
                <h4 className={cx('title')}>제품문의</h4>
                <a className={cx('tel')} href="tel:0809067000">
                  080-906-7000
                </a>
                <p className={cx('time')}>
                  상담시간 : 월 - 금 09:00 - 18:00
                  <br />
                  <small>(점심시간 : 12:00 - 13:00, 일/공휴일 휴무)</small>
                </p>
              </li>
              <li>
                <h4 className={cx('title')}>가맹점 주문 문의</h4>
                <a className={cx('tel')} href="tel:15888655">
                  1588-8655
                </a>
                <p className={cx('time')}>
                  상담시간 : 월 - 금 08:30 - 17:30
                  <br />
                  <small>(점심시간 : 12:00 - 13:00, 일/공휴일 휴무)</small>
                </p>
              </li>
            </ul>
            <select onChange={onChange} className={cx('familySite')}>
              <option value="">패밀리 사이트</option>
              <option value="https://www.pulmuone.co.kr/">풀무원</option>
              <option value="https://shop.pulmuone.co.kr/">#풀무원</option>
              <option value="https://2o.pulmuone.com/">풀무원투오</option>
              <option value="https://www.pulmuone-lohas.com/">풀무원건강생활</option>
              <option value="https://www.pulmuone-lohas.com/">풀무원로하스</option>
              <option value="http://greenjuice.pulmuone.com/">풀무원녹즙</option>
              <option value="https://www.kimchikan.com/">뮤지엄김치간</option>
              <option value="https://www.orga.co.kr/">올가홀푸드</option>
              <option value="https://pulstory.pulmuone.com/">푸드머스</option>
              <option value="https://www.pulmuonefnc.com/">풀무원푸드앤컬쳐</option>
              <option value="http://www.exofresh.co.kr/main/">엑소후래쉬</option>
              <option value="https://fis.pulmuonewater.com/">풀무원샘물FIS</option>
            </select>
            <div className={cx('logo')}>
              <img src={logo} alt="" />
            </div>
            <div className={cx('info')}>
              <ul className={cx('top')}>
                <li>
                  <Link to="/개인정보처리방침">개인정보처리방침&nbsp;</Link>
                </li>
                <li>
                  <a href="https://cp.pulmuone.kr:3444/cyber/" target="_blank" rel="noreferrer">
                    풀무원 사이버 감사실
                  </a>
                </li>
                <li>
                  <Link to="/이메일무단수집거부">이메일무단수집거부&nbsp;</Link>
                </li>
                <li>
                  <Link to="/회사소개/오시는-길">찾아오시는길&nbsp;</Link>
                </li>
              </ul>
              <ul className={cx('bottom')}>
                <li>풀무원샘물㈜&nbsp;</li>
                <li>ꞏ 대표자: 정희련 </li>
                <li>ꞏ 본점소재지: 경기도 포천시 이동면 화동로 1870번길 142-24</li>
                <li>ꞏ 서울사무국: 서울 송파구 송파대로 268(가락동 98-4) 한솔섬유빌딩 3층</li>
                <li>ꞏ 사업자등록번호: 301-81-71746 </li>
                <li>ꞏ 통신판매업신고: 제2011-서울송파-1283호</li>
              </ul>
            </div>
            <ul className={cx('sns')}>
              <li>
                <a href="https://www.facebook.com/pulsaem/" target="_blank" rel="noreferrer">
                  <img src={facebook} alt="페이스북" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/pulsaem/" target="_blank" rel="noreferrer">
                  <img src={instagram} alt="인스타그램" />
                </a>
              </li>
              <li>
                <a href="https://blog.naver.com/pul_saem" target="_blank" rel="noreferrer">
                  <img src={blog} alt="네이버 블로그" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import classNames from 'classnames/bind';

import styles from './S05.module.scss';
import product01 from '../../../assets/images/products/barrel/s05/product01.png';
import waterHeater_product01 from '../../../assets/images/products/barrel/s05/heater_product01.png';
import waterHeater_product03 from '../../../assets/images/products/barrel/s05/heater_product03.png';
import waterHeater_product04 from '../../../assets/images/products/barrel/s05/heater_product04.png';
import Products from '../../../components/product/Products';
import Nutrient from '../../../components/product/Nutrient';
import Download from '../../../components/product/Download';

const cx = classNames.bind(styles);

const S05 = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('limiter')}>
        <Products products={[{ sub: '경제적인 대용량 먹는샘물', title: '맛있는샘물 18.9L', image: product01 }]} color="#06542c" />
        <Nutrient
          data={{
            head: ['칼슘(Ca)', '나트륨(Na)', '마그네슘(Mg)', '칼륨(K)', '불소(F)'],
            body: [
              ['포천', '8.6 ~ 13.0', '4.8 ~ 7.4', '1.1 ~ 1.8', '0.6 ~ 1.0', '0.1 ~ 1.8'],
              ['하동', '7.06 ~ 11.99', '3.09 ~ 3.57', '0.86 ~ 1.78', '0.56 ~ 0.63', '불검출 ~ 0.20'],
            ],
          }}
          color="#8dc63e"
          leading
        />
        <Download
          title="맛있는샘물은 안심하고 드실 수 있습니다!"
          names={['수질 성적 검사서(포천)', '수질 성적 검사서(하동)']}
          files={['/static/report/Water_Report-Barrel_18L-Pocheon.pdf', '/static/report/Water_Report-Barrel_18L-Hadong.pdf']}
          color="#06542c"
        />
        <div className={cx('products')}>
          <Products
            title="맛있는샘물 18.9L 전용 냉온수기"
            products={[
              {
                sub: '생활 밀착형',
                title: '화이트 일반',
                image: waterHeater_product01,
                price: '200,000원',
                text: '임대시 보증금 : 30,000원\n월 4병 이상 음용 / 24개월 약정 기간',
                specs: [
                  { label: '제품규격', value: '(W)310×(D)310×(H)960' },
                  { label: '포장규격', value: '(W)325×(D)325×(H)985' },
                  { label: '무게', value: '제품 약 16kg, 포장 약 17kg' },
                  { label: '전력', value: '42.75kWh/m' },
                  { label: '출수능력', value: '냉수 3.0L/h, 온수 6.5/h' },
                ],
              },
              {
                sub: '합리적인 가격의',
                title: '애플 그린',
                image: waterHeater_product03,
                price: '220,000원',
                text: '임대시 보증금 : 40,000원\n월 4병 이상 음용 / 24개월 약정 기간',
                specs: [
                  { label: '제품규격', value: '(W)313×(D)323×(H)963' },
                  { label: '포장규격', value: '(W)325×(D)325×(H)985' },
                  { label: '무게', value: '제품 약 15kg, 포장 약 17kg' },
                  { label: '전력', value: '34.07kWh/m' },
                  { label: '출수능력', value: '냉수 2.7L/h, 온수 6.5/h' },
                ],
              },
              {
                sub: '슬림한 디자인의',
                title: '모던 블랙',
                image: waterHeater_product04,
                price: '230,000원',
                text: '임대 불가하며,\n신규 구매만 가능',
                specs: [
                  { label: '제품규격', value: '(W)272×(D)313×(H)965' },
                  { label: '포장규격', value: '(W)285×(D)340×(H)985' },
                  { label: '무게', value: '제품 약 16kg, 포장 약 18kg' },
                  { label: '전력', value: '32.12kWh/m' },
                  { label: '출수능력', value: '냉수 2.7L/h, 온수 6.5/h' },
                ],
              },
            ]}
            color="#06542c"
            width="33%"
          />
        </div>
        <div className={cx('precautions')}>
          <h3>※ 임대 시 주의사항</h3>
          <p>
            약정 기간 만료 전 임대 해약시 위약금 발생 가능할 수 있습니다.
            <br /> 단, 제주도와 울릉도 및 일부 도서 산간 지역은 제품 가격이 상이할 수 있으며,
            <br /> 냉온수기 조건은 지역에 따라 상이할 수 있습니다.
          </p>
        </div>
      </div>
    </div>
  );
};

export default S05;

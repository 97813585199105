import classNames from 'classnames/bind';

import styles from './S03.module.scss';
import product01 from '../../../assets/images/products/water/product01.jpg';
import product02 from '../../../assets/images/products/water/product02.jpg';
import product03 from '../../../assets/images/products/water/product03.jpg';
import product04 from '../../../assets/images/products/water/product04.jpg';
import Download from '../../../components/product/Download';
import Nutrient from '../../../components/product/Nutrient';
import Products from '../../../components/product/Products';

const cx = classNames.bind(styles);

const S03 = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('limiter')}>
        <Products
          products={[
            { sub: '가정 식수로 적합한', title: '풀무원샘물 2L', image: product01, text: '2L × 6개입' },
            { sub: '나들이, 1인 가정 식수는', title: '풀무원샘물 1.5L', image: product02, text: '1.5L × 6개입' },
            { sub: '외출, 아웃도어 용으로', title: '풀무원샘물 500mL', image: product03, text: '500mL × 20개입' },
            { sub: '소풍, 미팅 할 때', title: '풀무원샘물 330mL', image: product04, text: '330mL × 20개입' },
          ]}
          color="#06542c"
        />
        <Nutrient
          data={{
            head: ['칼슘(Ca)', '나트륨(Na)', '마그네슘(Mg)', '칼륨(K)', '불소(F)'],
            body: [['12.3 ~ 18.4', '6.2 ~ 9.3', '1.7 ~ 2.6', '0.4 ~ 0.7', '0.0 ~ 0.1']],
          }}
          color="#bed730"
        />
        <Download
          title="풀무원샘물은 안심하고 드실 수 있습니다!"
          names={['수질 성적 검사서(하동)', '수질 성적 검사서(포천이동)']}
          files={['/static/report/Water_Report-Water-Hadong.pdf', '/static/report/Water_Report-Water-Pocheon.pdf']}
          color="#06542c"
        />
      </div>
    </div>
  );
};

export default S03;

import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import axios from 'axios';
import moment from 'moment';

import styles from './Popup.module.scss';

const cx = classNames.bind(styles);

const storageKey = `@pmowtPpsPvntIds:${moment().format('YYMMDD')}`;

const Popup = () => {
  const [popups, setPopups] = useState([]);

  useEffect(() => {
    axios
      .get('https://pulmuone.console.flyground.co.kr/api/popups')
      .then(({ data: { success, data } }) => {
        // 조회 결과 있는 경우
        if (success && data.length > 0) {
          // 활성 상태 팝업 추출
          const activePopups = data.filter((popup) => popup.isActive);
          const raw = localStorage.getItem(storageKey);
          if (raw) {
            const ids = JSON.parse(raw);
            setPopups(activePopups.filter((popup) => !ids.includes(popup.id)));
          } else {
            setPopups(activePopups);
          }
        }
      })
      .catch((err) => console.error(err));
  }, []);

  const closeToday = (id) => {
    close(id);

    const raw = localStorage.getItem(storageKey);
    if (raw) {
      localStorage.setItem(storageKey, JSON.stringify([...JSON.parse(raw), id]));
    } else {
      localStorage.setItem(storageKey, JSON.stringify([id]));
    }
  };

  const close = (id) => {
    setPopups(popups.filter((popup) => popup.id !== id));
  };

  const click = (popup) => {
    if (popup.linkUrl) {
      window.open(popup.linkUrl, '_blank');
    }
  };

  if (popups.length > 0) {
    return (
      <div className={cx('container')}>
        {popups.map((popup, index) => (
          <div key={index} className={cx('popup')}>
            <div className={cx('header')}>
              <div className={cx('left')} onClick={() => closeToday(popup.id)}>
                오늘 하루 보지 않기
              </div>
              <div className={cx('right')} onClick={() => close(popup.id)}>
                닫기
              </div>
            </div>
            <img
              src={popup.fileUrl}
              alt={popup.title}
              className={cx(['image', { clickable: popup.linkUrl !== '' }])}
              onClick={() => click(popup)}
            />
          </div>
        ))}
      </div>
    );
  }

  return null;
};

export default Popup;

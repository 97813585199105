import { useState } from 'react';
import classNames from 'classnames/bind';

import styles from './PrivacyPolicy.module.scss';

const cx = classNames.bind(styles);

const PrivacyPolicy = () => {
  const [showBranches, setShowBranches] = useState(false);

  return (
    <>
      <div className={cx('container')}>
        <h3>제1조 총칙</h3>
        <p>
          (주)풀무원샘물은 (이하 "회사"라 함) 고객님의 소중한 개인정보 보호를 위하여 개인정보 보호 관련 법령을 준수하고 있습니다. 회사는
          개인정보처리방침을 통하여 고객의 소중한 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보 보호를 위해 어떠한 조치가
          취해지고 있는지 알려드립니다.
        </p>
        <h3>제2조 개인정보의 수집 항목 및 이용 목적</h3>
        <p>① 서비스 이용 시 수집하는 정보</p>
        <div className={cx('scrollable')}>
          <table cellSpacing={0}>
            <thead>
              <tr>
                <td>수집 목적</td>
                <td>필수/선택</td>
                <td>수집하는 항목</td>
                <td>보유기간</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>제품/배송/기타 문의</td>
                <td rowSpan={5}>필수</td>
                <td rowSpan={4}>이름, 이메일, 휴대전화번호, 주소,</td>
                <td>3년</td>
              </tr>
              <tr>
                <td>가맹점 주문/문의</td>
                <td>3년</td>
              </tr>
              <tr>
                <td>냉온수기 세척 신청</td>
                <td>3년</td>
              </tr>
              <tr>
                <td>가맹점 사업 신청/문의</td>
                <td>3년</td>
              </tr>
              <tr>
                <td>부정제보</td>
                <td>이름,이메일</td>
                <td>3년</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <p>② 서비스 이용과정에서 생성되거나 자동으로 수집하는 정보</p>
        <div className={cx('scrollable')}>
          <table cellSpacing={0}>
            <thead>
              <tr>
                <td>수집하는 항목</td>
                <td>수집 목적</td>
                <td>보유기간</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>사이트 접속 시 수집될 수 있는 정보 :</strong>
                  <br /> 서비스 이용 및 중지기록, 접속로그, 쿠키, 접속IP정보, 이용정지기록
                </td>
                <td>해킹 모니터링 및 문제 발생 시 원인 분석용 홍보 이벤트 및 행사 참여 서비스 개선</td>
                <td>6개월</td>
              </tr>
              <tr>
                <td>
                  <strong>모바일기기 이용 시 수집될 수 있는 정보 :</strong>
                  <br /> 단말기식별번호, 단말기 OS 정보, 광고식별자, 이동통신회사, PUSH 수신여부
                </td>
                <td>해킹 모니터링 및 문제 발생 시 원인 분석용 홍보 이벤트 및 행사 참여 서비스 개선</td>
                <td>6개월</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3>제3조 개인정보의 수집 방법</h3>
        <p>회사는 다음과 같은 방법으로 개인정보를 수집합니다.</p>
        <ul>
          <li>① 홈페이지 이용</li>
          <li>② 쿠키(cookie)에 의한 정보 수집</li>
        </ul>
        <h3>제4조 개인정보의 처리 위탁</h3>
        <p>① 회사는 원활한 개인정보 업무 처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.</p>
        <div className={cx('scrollable')}>
          <table cellSpacing={0}>
            <thead>
              <tr>
                <td>수탁 받는 자</td>
                <td>위탁 업무 내용</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong className={cx('popup')} onClick={() => setShowBranches(true)}>
                    가맹점
                  </strong>
                </td>
                <td>배송, 입금/미납안내, 고객불만 처리업무 </td>
              </tr>
              <tr>
                <td>플라이그라운드</td>
                <td>홈페이지 운영 및 유지보수</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <ul>
          <li>
            ② 회사는 위탁계약 체결 시, 관련 법령에 따라 목적 외 개인정보 처리금지, 기술적∙관리적 보호조치, 재 위탁 제한, 수탁자에 대한
            관리∙감독 등 책임에 관한 사항을 계약서 등 문서에 명시하고 수탁자가 개인정보를 안전하게 처리하는지 감독하고 있습니다.
          </li>
          <li>③ 회사는 위탁업무의 내용이나 수탁자가 변경될 경우 지체없이 본 개인정보처리방침을 통하여 공개합니다.</li>
        </ul>
        <h3>제5조 개인정보의 제3자 제공</h3>
        <ul>
          <li>
            ① 회사는 고객의 개인정보를 제2조(개인정보의 수집 항목 및 이용 목적)에서 고지한 범위내에서 사용하며, 동 범위를 초과하여
            이용하거나 타인을 포함한 제3자에게 임의 제공하지 않습니다.
          </li>
          <li>
            ② 다만, 수사목적으로 관계법률에서 정한 절차와 방법에 따라 수사기관이 개인정보 제공을 요구하는 경우나 기타 관계법률의 규정에 따른
            요청이 있는 경우에는 예외로 제공합니다.
          </li>
        </ul>
        <h3>제6조 개인정보의 보유 및 이용기간</h3>
        <p>
          회사는 수집된 개인정보는 수집 및 이용목적 또는 제공받은 목적이 달성된 경우 지체없이 파기합니다. 다만, 『전자상거래 등에서의 소비자
          보호에 관한 법률』 등과 관련 법령에 따라 보존할 필요가 있는 경우 아래와 같이 보유합니다.
        </p>
        <div className={cx('scrollable')}>
          <table cellSpacing={0}>
            <thead>
              <tr>
                <td>보존근거</td>
                <td>목적</td>
                <td>보유기간</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={4}>전자상거래 소비자 보호에 관한 법률</td>
                <td>계약 또는 청약철회</td>
                <td>5년</td>
              </tr>
              <tr>
                <td style={{ borderLeft: 0 }}>대금결제 및 재화 등의 공급에 관한 기록</td>
                <td>5년</td>
              </tr>
              <tr>
                <td style={{ borderLeft: 0 }}>소비자 불만 또는 분쟁처리에 관한 기록</td>
                <td>3년</td>
              </tr>
              <tr>
                <td style={{ borderLeft: 0 }}>표시∙광고에 관한 기록</td>
                <td>6개월</td>
              </tr>
              <tr>
                <td>통신비밀보호법</td>
                <td>통신사실 확인자료에 관한 기록</td>
                <td>3개월</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3>제7조 개인정보 파기절차 및 방법</h3>
        <ul>
          <li>
            ① 회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다.
            <p>
              1. 파기절차 : 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 내부 방침 및 제6조와 관련한 기타 법령에 정한 기간동안 저장된
              후 파기합니다. 동 개인정보는 법률에 의한 경우가 아니고서는 동의 받은 보유 목적 이외의 다른 목적으로 이용하지 않습니다.
            </p>
            <p>
              2. 파기방법
              <span>가. 종이에 출력된 개인정보 : 분쇄기로 분쇄하거나 소각</span>
              <span>나. 전자적 파일 형태로 저장된 개인정보 : 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제</span>
            </p>
          </li>
          <li>
            ② 이용자로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속
            보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
          </li>
        </ul>
        <h3>제8조 정보주체와 법정대리인의 권리, 의무 및 그 행사방법에 관한 사항</h3>
        <ul>
          <li>
            ① 이용자가 개인정보의 오류에 대한 정정 및 삭제를 요청한 경우에는 정정 및 삭제를 완료할 때까지 해당 개인정보를 이용 또는 제공하지
            않습니다. 이 경우, 잘못된 개인정보를 이용 또는 제공한 경우 지체 없이 수정하겠습니다.
          </li>
          <li>② 회사는 정보주체의 요청에 의해 해지 또는 삭제되는 개인정보는 개인정보의 파기절차 및 방법에 따라 처리합니다.</li>
          <li>
            ③ 개인정보의 열람, 정정, 삭제, 처리, 정지 요구가 다른 법령상의 의무를 준수하기 위하여 불가피한 경우, 다른 사람의 생명, 신체를
            해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우, 개인정보를 처리하지 아니하면 정보주체와
            약정한 서비스를 제공하지 못하는 등 계약이행이 곤란한 경우는 요구를 거절할 수 있습니다.
          </li>
          <li>④ 정보주체의 부정확한 정보 입력이나 타인의 개인정보를 도용한 경우 개인정보보호 관련 법령에 의해 처벌받을 수 있습니다.</li>
        </ul>
        <h3>제9조 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h3>
        <ul>
          <li>
            ① 회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.
            쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에
            저장됩니다.
          </li>
          <li>
            ② 회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
            <p>
              1. 쿠키의 사용 목적
              <span>가. 회사는 이용자의 편의를 위하여 쿠키를 운영합니다.</span>
              <span>나. 회사가 쿠키를 통해 수집하는 정보는 회원 ID에 한하며, 그 외의 다른 정보는 수집하지 않습니다.</span>
              <span>다. 개인의 관심 분야에 따라 차별화된 정보를 제공</span>
              <span>
                라. 회원과 비회원의 접속빈도 또는 머문 시간 등을 분석하여 이용자의 취향과 관심분야를 파악하여 타겟(target) 마케팅에 활용
              </span>
              <span>
                마. 쇼핑한 품목들에 대한 정보와 관심 있게 둘러본 품목들에 대한 자취를 추적하여 다음 번 쇼핑 때 개인 맞춤 서비스를 제공
              </span>
              <span>바. 유료서비스 이용 시 이용기간 안내</span>
              <span>사. 회원들의 습관을 분석하여 서비스 개편 등의 척도</span>
            </p>
            <p>
              2. 쿠키 설치 허용 여부를 지정하는 방법
              <span>가. Internet Explorer : 도구 메뉴 &gt; 인터넷 옵션 &gt; 개인정보 탭 &gt; 개인정보처리 수준 설정</span>
              <span>나. Chrome : 설정 메뉴 &gt; 고급 설정 표시 선택 &gt; 개인정보-콘텐츠 설정 &gt; 쿠키 수준 설정</span>
              <span>다. firefox : 옵션 메뉴 &gt; 개인정보 &gt; 방문기록-사용자 정의 설정 &gt; 쿠키 수준 설정</span>
              <span>라. 환경설정 메뉴 &gt; 개인정보 탭 &gt; 쿠키 및 웹 사이트 데이터 수준 설정</span>
            </p>
            <p>3. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</p>
          </li>
        </ul>
        <h3>제10조 개인정보의 기술적/관리적 보호 대책</h3>
        <p>
          회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조, 훼손 등이 되지 아니하도록 안전성을 확보하기 위하여
          다음과 같이 기술적·관리적 보호대책을 강구하고 있습니다.
        </p>
        <ul>
          <li>① 관리적 조치 : 내부관리계획 수립 시행, 정기적 직원 교육 등</li>
          <li>
            ② 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치 등
          </li>
          <li>③ 물리적 조치 : 전산실, 자료보관실 등의 접근통제 등</li>
        </ul>
        <h3>제11조 개인정보 보호책임자 및 담당부서</h3>
        <div className={cx('scrollable')}>
          <table cellSpacing={0}>
            <thead>
              <tr>
                <td>개인정보 보호책임자</td>
                <td>개인정보관리 부서</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  성명 : 백동옥
                  <br /> 소속부서 : 운영총괄본부
                  <br /> 이메일 : dobaik@pulmuone.com
                </td>
                <td>
                  부서명 : 마케팅팀
                  <br /> 담당자 : 이예지
                  <br /> 연락처 : 02-2140-8764
                  <br /> 이메일 : yjleeey@pulmuone.com
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3>제12조. 정보주체의 권익침해에 대한 구제 방법</h3>
        <p>기타 개인정보 침해/피해와 관련한 상담이 필요한 경우에는 아래 기관으로 문의하시기 바랍니다.</p>
        <ul>
          <li>① 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)</li>
          <li>② 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)</li>
          <li>③ 경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번없이 182)</li>
          <li>④ 개인정보 분쟁조정위원회 (kopico.go.kr / 1833-6972)</li>
        </ul>
        <h3>제13조 고지의 의무</h3>
        <p>
          이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 가능한 변경사항의
          시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
        </p>
        <p>
          개인정보처리방침 버전번호: v1.0
          <span>- 공고일자 : 2022년 3월 1일</span>
          <span>- 시행일자 : 2022년 3월 8일</span>
          <span>이전 버전 개인정보 처리방침은 공지사항에서 확인할 수 있습니다.</span>
        </p>
      </div>
      {showBranches && (
        <div className={cx('branches')}>
          <div className={cx('close')} onClick={() => setShowBranches(false)}>
            닫기
          </div>
          <ul>
            <li>경기유통가맹점</li>
            <li>경수유통특판</li>
            <li>과천특판</li>
            <li>동서울유통가맹점</li>
            <li>동탄화성가맹점[신]</li>
            <li>만안가맹점</li>
            <li>분당수지가맹점</li>
            <li>샘물더뉴안양가맹점</li>
            <li>샘물평촌산본가맹점</li>
            <li>수원유통가맹점</li>
            <li>안산가맹점</li>
            <li>안양가맹점</li>
            <li>의왕가맹점[신]</li>
            <li>죽전수지가맹점</li>
            <li>호계상사가맹점</li>
            <li>샘물화성동부가맹점</li>
            <li>남분당가맹점</li>
            <li>분당가맹점[신]</li>
            <li>서분당가맹점</li>
            <li>신분당가맹점</li>
            <li>남강동가맹점[신]</li>
            <li>대명특판</li>
            <li>샘물사랑가맹점</li>
            <li>샘물성남가맹점</li>
            <li>서초상사가맹점</li>
            <li>선달유통특판</li>
            <li>성일유통특판</li>
            <li>시원유통특판</li>
            <li>신역삼가맹점</li>
            <li>압구정가맹점</li>
            <li>오륜가맹점</li>
            <li>옹달샘물가맹점</li>
            <li>원민유통가맹점</li>
            <li>유정특판</li>
            <li>청담가맹점[신]</li>
            <li>테크노마트특판</li>
            <li>판교분당가맹점</li>
            <li>푸른샘유통가맹점</li>
            <li>하남가맹점</li>
            <li>그린유통가맹점</li>
            <li>동백구성가맹점</li>
            <li>북수원가맹점</li>
            <li>수원서부가맹점</li>
            <li>샘물신원주가맹점</li>
            <li>논현가맹점[신]</li>
            <li>동청담가맹점</li>
            <li>반포가맹점</li>
            <li>포이가맹점</li>
            <li>강산특판</li>
            <li>경기일산가맹점[신]</li>
            <li>남대문가맹점[신]</li>
            <li>마두가맹점</li>
            <li>샘물고양가맹점</li>
            <li>샘물춘천가맹점</li>
            <li>서울중구가맹점[신]</li>
            <li>서일산가맹점[신]</li>
            <li>신금호특판</li>
            <li>신서강가맹점</li>
            <li>일산중앙가맹점[신]</li>
            <li>일산파주가맹점</li>
            <li>종로특판</li>
            <li>파주유통가맹점</li>
            <li>샘물종로용산가맹점</li>
            <li>공덕가맹점[신]</li>
            <li>광화문가맹점</li>
            <li>샘물베스트가맹점</li>
            <li>샘물서울중앙가맹점</li>
            <li>샘물신명동가맹점</li>
            <li>서교가맹점</li>
            <li>서울서부가맹점</li>
            <li>신촌가맹점</li>
            <li>양재가맹점[신]</li>
            <li>은평가맹점[신]</li>
            <li>응암가맹점</li>
            <li>제일유통가맹점</li>
            <li>테크노유통특판[신]</li>
            <li>혜화특판</li>
            <li>남양주가맹점[신]</li>
            <li>동신가맹점</li>
            <li>미래유통가맹점</li>
            <li>번동가맹점</li>
            <li>새성수가맹점</li>
            <li>새장위가맹점</li>
            <li>서초청정수가맹점</li>
            <li>성수가맹점</li>
            <li>수유유통가맹점</li>
            <li>스피드유통특판</li>
            <li>양지가맹점</li>
            <li>종암길음가맹점</li>
            <li>중랑중화가맹점</li>
            <li>강원삼척가맹점</li>
            <li>돈암정릉가맹점[신]</li>
            <li>샘물강릉가맹점</li>
            <li>샘물속초가맹점</li>
            <li>서울유통특판[신]</li>
            <li>성북가맹점[신]</li>
            <li>신내가맹점</li>
            <li>의정부유통가맹점</li>
            <li>진접가맹점[신]</li>
            <li>청량리특판</li>
            <li>태능유통가맹점</li>
            <li>토평교문가맹점</li>
            <li>가양유통가맹점[신]</li>
            <li>강남유통 특판</li>
            <li>강우유통특판</li>
            <li>김포유통특판[신]</li>
            <li>반월시화가맹점[신]</li>
            <li>사당가맹점</li>
            <li>새송탄가맹점</li>
            <li>샘물경기그린유통가맹점</li>
            <li>샘물정왕가맹점</li>
            <li>서울남부가맹점</li>
            <li>서해가맹점</li>
            <li>신안성가맹점</li>
            <li>안중가맹점</li>
            <li>샘물광주영업소가맹점</li>
            <li>승진상사가맹점[신]</li>
            <li>여주장호원가맹점</li>
            <li>용인가맹점[신]</li>
            <li>이천중앙가맹점</li>
            <li>정이유통가맹점[신]</li>
            <li>가나유통가맹점[신]</li>
            <li>개봉유통가맹점</li>
            <li>구로유통가맹점</li>
            <li>노량진가맹점</li>
            <li>대부가맹점</li>
            <li>독산유통가맹점[신]</li>
            <li>동인천가맹점</li>
            <li>무한유통가맹점</li>
            <li>샘물부천가맹점</li>
            <li>샘물인천서부가맹점</li>
            <li>서경유통가맹점</li>
            <li>서인천가맹점</li>
            <li>시흥가맹점</li>
            <li>신남인천가맹점</li>
            <li>양천가맹점[신]</li>
            <li>연수가맹점</li>
            <li>옥례특판</li>
            <li>워터클리닉가맹점</li>
            <li>인천유통가맹점</li>
            <li>개미가맹점</li>
            <li>고성가맹점</li>
            <li>그린가맹점[신]</li>
            <li>녹산특판</li>
            <li>동래특판</li>
            <li>마니가맹점</li>
            <li>미르유통가맹점</li>
            <li>부산청수특판</li>
            <li>샘물바른샘물가맹점</li>
            <li>샘물수정상사가맹점</li>
            <li>샘물시원가맹점</li>
            <li>샘물신거제가맹점</li>
            <li>샘물오투가맹점</li>
            <li>샘물일도유통가맹점</li>
            <li>샘물창원유통가맹점</li>
            <li>샘물천수가맹점</li>
            <li>서강유통가맹점</li>
            <li>우리생수가맹점</li>
            <li>장유가맹점</li>
            <li>청해유통특판</li>
            <li>최고수상사특판[신]</li>
            <li>통영가맹점[신]</li>
            <li>샘물양산가맹점</li>
            <li>가람상사가맹점</li>
            <li>고려특판</li>
            <li>구미특판</li>
            <li>다나유통가맹점</li>
            <li>대박농약가맹점</li>
            <li>북대구특판</li>
            <li>샘물미래그린가맹점</li>
            <li>서대구특판</li>
            <li>소원상사가맹점</li>
            <li>신의성가맹점[신]</li>
            <li>안동해룡가맹점</li>
            <li>에이스상사가맹점</li>
            <li>영풍상사가맹점[신]</li>
            <li>예맥상사가맹점[신]</li>
            <li>조은물가맹점</li>
            <li>지오상사가맹점</li>
            <li>진영생수(신)가맹점</li>
            <li>진주가맹점[신]</li>
            <li>진주상사가맹점</li>
            <li>하나생수가맹점</li>
            <li>하동남해가맹점[신]</li>
            <li>뉴경주가맹점</li>
            <li>동대구태웅가맹점</li>
            <li>북포항특판</li>
            <li>상아가맹점</li>
            <li>가야통상특판</li>
            <li>밀양특판</li>
            <li>밀양특판</li>
            <li>샘물서울산가맹점</li>
            <li>샘물울산중구가맹점</li>
            <li>울산광역가맹점[신]</li>
            <li>울산남구가맹점</li>
            <li>울산생수특판</li>
            <li>울산유통가맹점</li>
            <li>울산중앙가맹점</li>
            <li>군산(신)가맹점</li>
            <li>김제가맹점</li>
            <li>남원샘물나라가맹점</li>
            <li>대음성특판</li>
            <li>대전샘물가맹점[신]</li>
            <li>샘물계룡가맹점[신]</li>
            <li>성원가맹점</li>
            <li>신둔산가맹점</li>
            <li>엑스포가맹점</li>
            <li>익산가맹점</li>
            <li>정읍가맹점</li>
            <li>한밭가맹점</li>
            <li>호남가맹점</li>
            <li>뉴대전특판</li>
            <li>금왕가맹점</li>
            <li>대전한솔가맹점</li>
            <li>샘물증평가맹점</li>
            <li>서산특판</li>
            <li>세종가맹점</li>
            <li>신대천가맹점</li>
            <li>신서산가맹점</li>
            <li>신태안가맹점</li>
            <li>아산가맹점</li>
            <li>아산민지특판</li>
            <li>홍성유통가맹점</li>
            <li>뉴온양가맹점</li>
            <li>금정가맹점</li>
            <li>대왕물류가맹점</li>
            <li>대전서부특판</li>
            <li>물사랑가맹점</li>
            <li>반천종합상사가맹점</li>
            <li>신논산가맹점</li>
            <li>신제천가맹점</li>
            <li>청주새한특판</li>
            <li>충주생수가맹점</li>
            <li>다원상사가맹점</li>
            <li>오대샘물유통가맹점</li>
            <li>광양(신)특판</li>
            <li>광주남구가맹점[신]</li>
            <li>광주서구(신)가맹점</li>
            <li>광주영천가맹점</li>
            <li>광주특판</li>
            <li>광주한라특판가맹점</li>
            <li>금강가맹점</li>
            <li>나주가맹점[신]</li>
            <li>목포가맹점</li>
            <li>샘물순천가맹점</li>
            <li>샘물이알가맹점</li>
            <li>서광주가맹점[신]</li>
            <li>스카이유통가맹점</li>
            <li>여수(신)가맹점</li>
            <li>여천(신)가맹점</li>
            <li>여천사랑가맹점</li>
            <li>장수특판 </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default PrivacyPolicy;

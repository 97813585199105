import React from 'react';
import classNames from 'classnames/bind';

import styles from './Specs.module.scss';

const cx = classNames.bind(styles);

const Specs = ({ data }) => {
  return (
    <div className={cx('container')}>
      {data.map(({ label, value }, index) => (
        <div key={index} className={cx('row')}>
          <div className={cx('label')}>{label}</div>
          <div className={cx('value')}>{value}</div>
        </div>
      ))}
    </div>
  );
};

export default Specs;

import classNames from 'classnames/bind';

import styles from './S03.module.scss';
import waterpack from '../../../assets/images/products/tuo/s03/waterpack.jpg';

const cx = classNames.bind(styles);

const S03 = () => {
  return (
    <div className={cx('container')}>
      <h2 className={cx('headline')}>미네랄 워터팩</h2>
      <img src={waterpack} alt="미네랄 워터팩" />
      <div className={cx('columns')}>
        <div className={cx('column')}>
          <img src={require('../../../assets/images/products/tuo/s03/icon01.jpg')} alt="3중 레이어링 구조" />
          <div className={cx('title')}>
            3중
            <br />
            레이어링 구조
          </div>
        </div>
        <div className={cx('column')}>
          <img src={require('../../../assets/images/products/tuo/s03/icon02.jpg')} alt="폐기물 발생량 54% 절감" />
          <div className={cx('title')}>
            폐기물 발생량
            <br />
            54% 절감
            <br />
            <small>
              <small>*생수 2L PET 대비</small>
            </small>
          </div>
        </div>
        <div className={cx('column')}>
          <img src={require('../../../assets/images/products/tuo/s03/icon03.jpg')} alt="재활용 분리배출 가능" />
          <div className={cx('title')}>
            재활용
            <br />
            분리배출 가능
          </div>
        </div>
        <div className={cx('column')}>
          <img src={require('../../../assets/images/products/tuo/s03/icon04.jpg')} alt="냉온수 출수 가능한 전용 디스펜서" />
          <div className={cx('title')}>
            냉온수 출수 가능한
            <br />
            전용 디스펜서
            <br />
            <small>
              <small>*별도 구매</small>
            </small>
          </div>
        </div>
      </div>
      <div className={cx('box')}>
        폐기물 발생량은 당사 초경량 생수 2L와의 기준치로 각 가정 내 사용 환경에 따라 폐기물량은 달라질 수 있습니다.
      </div>
    </div>
  );
};

export default S03;

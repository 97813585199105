import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from './S04.module.scss';

const cx = classNames.bind(styles);

const Section = () => {
  return (
    <div className={cx('container')}>
      <div className={cx('limiter')}>
        <h2 className={cx('title')}>
          대용량 먹는샘물로
          <br />
          건강한 수분 섭취를 시작해 보세요!
        </h2>
        <ul className={cx(['buttons'])}>
          <li className={cx('button')}>
            <Link to="/고객센터/문의하기">
              <div className={cx('icon')} />
              <div className={cx('label')}>주문상담 신청하기</div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Section;
